import { MapView } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FlagIcon from '@mui/icons-material/Flag';
import LinkIcon from '@mui/icons-material/Link';
import UpdateIcon from '@mui/icons-material/Update';
import { Alert, Button, ButtonGroup, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, Hidden, InputLabel, MenuItem, Paper, Select, styled, SvgIcon, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/system';
import { BusinessPartnerInfo, Display, FlightInfo, FlightStatus, GeoInformation, MapStyle, OperationPartnerInfo, PartnerKind, Port } from "adoms-common-lib";
import { Auth, Geo } from "aws-amplify";
import axios from 'axios';
import * as geolib from "geolib";
import React, { useEffect, useMemo, useRef } from 'react';
import type { MapRef } from 'react-map-gl';
import { Marker, NavigationControl, Popup } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as DroneIcon } from "../../../assets/icons/quadcopter.svg";
import { FlightCardForDroneGeoInformationView } from '../../../components/molecule/FlightCardForDroneGeoInformationView';
import { CreateMapURLDialog } from '../../../components/organisms/CreateMapURLDialog';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { APIConnector } from "../../../connector/APIConnector";
import { useUserInfoContext } from '../../../common/UserContext';

const drawerWidth = 260;

const FlightCardListPaper = styled(Paper)(({ theme }) => ({
    maxHeight: `calc(100vh - 280px)`,
    maxWidth: "300px",
    backgroundColor: "rgb(121,121,121,0.4)",
    zIndex: 30,
    position: "absolute",
    overflow: "auto",
    padding: "8px 8px 0px"
}));

const useStyles = makeStyles()((theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default,
        height: "100vh"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,

    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    errorMessage: {
        padding: "10px",
        marginBottom: "20px",
        marginTop: "5px"
    }
}));

type AmplifyGeoConfig = {
    geo?: {
        amazon_location_service?: AmplifyGeoOptions;
        AmazonLocationService?: AmplifyGeoOptions;
    };
};

type AmplifyGeoOptions = {
    maps?: {
        neutral: string,
        satellite: string
    };
    region: string;
};

type GeoInformationPerDroneId = {
    droneId: string,
    geoInformationList: GeoInformation[]
};

/**
 * ドローン位置情報
 */
export default function DroneGeoInformationView() {
    const { classes, cx } = useStyles();
    const navigate = useNavigate();

    const amplifyConfig = Geo.configure() as AmplifyGeoConfig;
    Auth.configure();
    const geoConfig = useMemo(
        () =>
            amplifyConfig.geo?.amazon_location_service ??
            amplifyConfig.geo?.AmazonLocationService ??
            ({} as AmplifyGeoOptions),
        [amplifyConfig]
    );
    const styleProps = useMemo(
        () => ({
            height: `calc(100vh - 160px)`,
            position: 'relative',
            width: '100%',
        }),
        []
    );

    const [isOpen, setOpen] = React.useState(false);
    const [geoInformationListPerDroneIdList, setGeoInformationListPerDroneIdList] = React.useState<GeoInformationPerDroneId[]>([]);
    const [lastGeoInformationPerDroneIdList, setLastGeoInformationPerDroneIdList] = React.useState<{
        droneId: string;
        geoInformation: GeoInformation
    }[]>([]);
    const [isUpdateMap, setUpdateMap] = React.useState(true);
    const [dronePopupInfo, setDronePopupInfo] = React.useState<GeoInformation | undefined>();
    const [portPopupInfo, setPortPopupInfo] = React.useState<Port | undefined>();
    const [getGeoInformationTime, setGetGeoInformationTime] = React.useState<string>(Display.getNowJSTToString());
    const [mapStyle, setMapStyle] = React.useState<MapStyle>(MapStyle.neutral);
    const [geoConfigMapStyle, setGeoConfigMapStyle] = React.useState<string | undefined>(geoConfig.maps?.neutral);
    const [portErrorMessage, setPortErrorMessage] = React.useState<string | undefined>(undefined);
    const [geoInformationErrorMessage, setGeoInformationErrorMessage] = React.useState<string | undefined>(undefined);
    const [geoInformationByFlightIdErrorMessage, setGeoInformationByFlightIdErrorMessage] = React.useState<string | undefined>(undefined);
    const [mapErrorMessage, setMapErrorMessage] = React.useState<string | undefined>(undefined);
    const [userErrorMessage, setUserErrorMessage] = React.useState<string | undefined>(undefined);
    const [allPortList, setAllPortList] = React.useState<Port[]>([]);
    const [isAutoUpdateMap, setAutoUpdateMap] = React.useState<boolean>(true);
    const [notFocusTime, setnotFocusTime] = React.useState<Date | undefined>(undefined);
    const [operatingFlightInfoList, setOperatingFlightInfoList] = React.useState<FlightInfo[]>([]);
    const [selectedFlightId, setSelectedFlightId] = React.useState<string | undefined>();
    const [geoInformationListPerFlightId, setGeoInformationListPerFlightId] = React.useState<GeoInformation[]>([]);
    const [isCreateMapURLDialogOpen, setCreateMapURLDialogOpen] = React.useState<boolean>(false);
    const [businessPartnerInfoList, setBusinessPartnerInfoList] = React.useState<BusinessPartnerInfo[]>([]);
    const [selectedBusinessPartnerInfo, setSelectedBusinessPartnerInfo] = React.useState<BusinessPartnerInfo>();
    const [minMaxLanLng, setMinMaxLanLng] = React.useState<{
        minLat: number,
        maxLat: number,
        minLng: number,
        maxLng: number
    } | undefined>(undefined);
    const [portLatLonList, setPortLatLonList] = React.useState<{
        longitude: number;
        latitude: number;
    }[]>([]);
    const mapRef = useRef<MapRef>(null);
    const userInfoContext = useUserInfoContext();
    const [userInfo, setUserInfo] = React.useState(userInfoContext.userInfo);

    useEffect(() => {
        setUserInfo(userInfoContext.userInfo);
        if (userInfoContext.isError) {
            setUserErrorMessage("ユーザー情報を取得できませんでした。");
        }
    }, [userInfoContext]);

    /**
     * 初回のみポートを取得する
     */
    useEffect(() => {
        const fetchAllPortList = async () => {
            if (userInfo) {
                let c: APIConnector = APIConnector.instance;
                await c.getPortListForOperation().then(async (portList: Port[]) => {
                    setAllPortList(portList);
                    if (userInfo.userPartnerInfo.partnerKind === PartnerKind.Operation) {
                        let visibleBusinessPartnerInfoList = (userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).visibleBusinessPartnerInfoList;
                        setBusinessPartnerInfoList(visibleBusinessPartnerInfoList);
                        if (visibleBusinessPartnerInfoList.length > 0) {
                            console.log(visibleBusinessPartnerInfoList[0])
                            setSelectedBusinessPartnerInfo(visibleBusinessPartnerInfoList[0]);
                            calculateMapCenterPoint(createPortLatLonList(visibleBusinessPartnerInfoList[0].businessPartnerId, portList));
                        };
                    } else if (userInfo.userPartnerInfo.partnerKind === PartnerKind.Business) {
                        let visibleBusinessPartnerInfo = (userInfo.userPartnerInfo.partnerInfo as BusinessPartnerInfo);
                        setBusinessPartnerInfoList([visibleBusinessPartnerInfo]);
                        setSelectedBusinessPartnerInfo(visibleBusinessPartnerInfo);
                        calculateMapCenterPoint(createPortLatLonList(visibleBusinessPartnerInfo.businessPartnerId, portList));
                    }
                }).catch((error) => {
                    console.log(error);
                    if (axios.isAxiosError(error)
                        && typeof error.response !== "undefined"
                        && error.response.status === 403) {
                        handleToForbidden403Page();
                    } else {
                        setPortErrorMessage("ポート情報を取得できませんでした。");
                    };
                });
            }
        };

        fetchAllPortList();
    }, [userInfo]);

    /**
     * 画面がアクティブ、かつisUpdateMapがtrueの時に
     * 初回のみドローンの位置情報を取得する
     */
    useEffect(() => {
        if (isUpdateMap) {
            fetchDroneData();
        };
    }, [isUpdateMap]);

    /**
     * 画面がアクティブ、かつisUpdateMapがtrueの時に
     * 5秒間隔でドローンの位置情報を取得する
     */
    useEffect(() => {
        let timeoutId: number | undefined
        if (isUpdateMap) {
            timeoutId = window.setTimeout(() => fetchDroneData(), 5 * 1000);
            return () => {
                if (typeof timeoutId !== "undefined") {
                    window.clearTimeout(timeoutId);
                };
            };
        };
    }, [geoInformationListPerDroneIdList, isUpdateMap]);

    /**
     * フライトIDごとのドローン位置情報を取得する
     */
    useEffect(() => {
        if (selectedFlightId) {
            const fetchGeoInformationByFlightId = async () => {
                let c: APIConnector = APIConnector.instance;
                await c.getGeoInformationByFlightId(selectedFlightId).then((geoInformationList: GeoInformation[]) => {
                    setGeoInformationByFlightIdErrorMessage(undefined);
                    setGeoInformationListPerFlightId(geoInformationList);
                }).catch((error) => {
                    console.log(error);
                    if (axios.isAxiosError(error)
                        && typeof error.response !== "undefined"
                        && error.response.status === 403) {
                        handleToForbidden403Page();
                    } else {
                        setGeoInformationByFlightIdErrorMessage("フライトごとのドローン位置情報を取得できませんでした。");
                    };
                });
            };
            fetchGeoInformationByFlightId();
        } else {
            setGeoInformationListPerFlightId([]);
        }
    }, [selectedFlightId]);

    /**
     * ドローンの位置情報を取得する
     */
    const fetchDroneData = () => {

        const getGeoInformation = async () => {
            setGetGeoInformationTime(Display.getNowJSTToString());
            setAutoUpdateMap(true);

            let apiConnector: APIConnector = APIConnector.instance;
            let getGeoInformation = apiConnector.getGeoInformation();
            let getFlightListByFlightDate = apiConnector.getFlightListByFlightDate(Display.getNowDateWithString());
            await Promise.all([getFlightListByFlightDate, getGeoInformation]).then(async value => {

                // 飛行中のフライト情報を取得する
                let flightList = value[0].filter((v) => (
                    v.status == FlightStatus.OBTakeoff ||
                    v.status == FlightStatus.OBInFlightClimb ||
                    v.status == FlightStatus.OBInFlightCruise ||
                    v.status == FlightStatus.OBInFlightDescent ||
                    v.status == FlightStatus.OBAfterLanding ||
                    v.status == FlightStatus.RTTakeoff ||
                    v.status == FlightStatus.RTInFlightClimb ||
                    v.status == FlightStatus.RTInFlightCruise ||
                    v.status == FlightStatus.RTInFlightDescent ||
                    v.status == FlightStatus.RTAfterLanding
                ));
                setOperatingFlightInfoList(flightList);

                // ドローン位置情報を取得する
                setGeoInformationErrorMessage(undefined);
                console.log(value[1]);

                let newGeoInformationListPerDroneIdList: GeoInformationPerDroneId[] = new Array();
                let lastGeoInformationListPerDroneIdList: {
                    droneId: string,
                    geoInformation: GeoInformation
                }[] = new Array();
                if (value[1].size !== 0) {
                    // 直近の位置情報を取得する
                    value[1].forEach(function ({ last1MinuteGeoInformations, last8HoursGeoInformation }, droneId) {
                        if (last1MinuteGeoInformations
                            && last1MinuteGeoInformations?.length !== 0) {
                            let geoInformationListPerDroneId: GeoInformationPerDroneId = {
                                droneId: droneId,
                                geoInformationList: last1MinuteGeoInformations
                            };
                            newGeoInformationListPerDroneIdList.push(geoInformationListPerDroneId);
                        } else if (last8HoursGeoInformation) {
                            let geoInformationListPerDroneId = {
                                droneId: droneId,
                                geoInformation: last8HoursGeoInformation
                            };
                            lastGeoInformationListPerDroneIdList.push(geoInformationListPerDroneId);
                        };
                    });
                };

                if (isUpdateMap) {
                    setGeoInformationListPerDroneIdList(newGeoInformationListPerDroneIdList);
                    setLastGeoInformationPerDroneIdList(lastGeoInformationListPerDroneIdList);
                } else {
                    setGeoInformationListPerDroneIdList([...geoInformationListPerDroneIdList]);
                    setLastGeoInformationPerDroneIdList([...lastGeoInformationPerDroneIdList]);
                };
            }).catch(error => {
                console.log("error:" + error.message);
                setGeoInformationErrorMessage("ドローンの位置情報を取得できませんでした");
            });
        };

        if (document.hasFocus()) {
            // 画面がアクティブの場合
            setnotFocusTime(undefined);
            getGeoInformation();
        } else {
            const nowTime = new Date();
            if (!notFocusTime) {
                setnotFocusTime(new Date());
            }
            if (notFocusTime && Math.floor((nowTime.getTime() - notFocusTime.getTime()) / (1000 * 60)) >= 1) {
                // 画面からフォーカスが外れてから1分以上経過した場合
                setAutoUpdateMap(false);
                // 画面が非アクティブの場合、前回取得したドローンの位置情報を設定する
                setGeoInformationListPerDroneIdList([...geoInformationListPerDroneIdList]);
            } else {
                // 画面からフォーカスが外れているが、経過した時間が１分未満の場合
                getGeoInformation();
            };
        };
    };

    /**
     * メニューバーが開かれたときのハンドラ
     */
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    /**
     * メニューバーが閉じられた時のハンドラ
     */
    const handleDrawerClose = () => {
        setOpen(false);
    };

    /**
     * ドローンの経路（マーカー）の色をグラデーションで表示する。
     * 先頭のマーカーはドローンのアイコンを表示させる。
     * @param locationIndex 
     */
    const handleDisplayLocationIcon = (locationIndex: number, lastGeoInformationFlg: boolean) => {
        let colorBrightness = 0;

        if (locationIndex === 0) {
            colorBrightness = 0;
        } else if (locationIndex === 1 || locationIndex === 2) {
            colorBrightness = 0.2;
        } else if (locationIndex === 3 || locationIndex === 4) {
            colorBrightness = 0.3;
        } else if (locationIndex === 5 || locationIndex === 6) {
            colorBrightness = 0.4;
        } else {
            colorBrightness = 0.5;
        };

        if (locationIndex === 0) {
            if (lastGeoInformationFlg) {
                return (
                    <SvgIcon
                        style={{
                            color: "#797979",
                            stroke: "#ffffff",
                            strokeWidth: "0.5px",
                        }}>
                        <DroneIcon />
                    </SvgIcon>
                );
            }
            if (mapStyle === MapStyle.satellite) {
                return (
                    <SvgIcon
                        style={{
                            color: "#ffffff",
                            stroke: "#000000",
                            strokeWidth: "0.5px",
                        }}>
                        <DroneIcon />
                    </SvgIcon>
                );
            } else {
                return (
                    <SvgIcon
                        style={{
                            stroke: "#ffffff",
                            strokeWidth: "0.5px"
                        }}>
                        <DroneIcon />
                    </SvgIcon>
                );
            }
        } else {
            return (
                <FiberManualRecordIcon
                    style={{ color: lighten("#797979", colorBrightness), fontSize: 10 }} />
            );
        };
    };

    /**
     * 選択されたフライトのドローンの経路（マーカー）の色を表示する。
     * @param index 
     */
    const handleDisplayLocationIconPerFlightId = (index: number) => {

        return (
            <FiberManualRecordIcon
                style={{ color: "#e06666", fontSize: 10 }} />
        );
    };

    /**
     * ドローン用ポップアップを表示させる
     */
    const displayDronePopupInfo = () => {
        if (dronePopupInfo) {
            let sendTime = Display.getTimeMiniteSecondStringFromISO8601(dronePopupInfo.sendTime);
            return (
                // 位置情報のマーカーのzIndexが最大240となり得るため、zIndexをそれ以上とする
                <Popup
                    longitude={dronePopupInfo.longitude}
                    latitude={dronePopupInfo.latitude}
                    anchor="bottom"
                    onClose={() => setDronePopupInfo(undefined)}
                    style={{ zIndex: 250 }}>
                    <Typography>{"通過時刻：" + sendTime}</Typography>
                    <Typography>{"フライトID：" + dronePopupInfo.flightId}</Typography>
                    <Typography>{"ドローンID：" + dronePopupInfo.droneId}</Typography>
                    <Typography>{"緯度：" + dronePopupInfo.latitude}</Typography>
                    <Typography>{"経度：" + dronePopupInfo.longitude}</Typography>
                    <Typography>{"高度：" + dronePopupInfo.altitude + "m"}</Typography>
                </Popup>
            );
        };
    };

    /**
     * ポート用ポップアップを表示させる
     */
    const displayPortPopupInfo = () => {
        if (portPopupInfo) {
            return (
                <Popup
                    longitude={Number(portPopupInfo.longitude)}
                    latitude={Number(portPopupInfo.latitude)}
                    anchor="bottom"
                    onClose={() => setPortPopupInfo(undefined)}>
                    <Typography >{"ポートID：" + portPopupInfo.id}</Typography>
                    <Typography>{"ポート名：" + portPopupInfo.name}</Typography>
                    <Typography>{"パートナーID：" + portPopupInfo.businessPartnerId}</Typography>
                    <Typography>{"緯度：" + portPopupInfo.latitude}</Typography>
                    <Typography>{"経度：" + portPopupInfo.longitude}</Typography>
                </Popup>
            );
        };
    };

    /**
     * 地図の表示形式が変更された時のハンドラ
     * @param mapStyle 
     */
    const handleChangeMapStyle = (mapStyle: MapStyle) => {
        setMapStyle(mapStyle);
        if (mapStyle === MapStyle.neutral) {
            setGeoConfigMapStyle(geoConfig.maps?.neutral);
        } else if (mapStyle === MapStyle.satellite) {
            setGeoConfigMapStyle(geoConfig.maps?.satellite);
        };
    };

    /**
     * 403ページに遷移させる
     * @param navigate 
     */
    const handleToForbidden403Page = () => {
        navigate("/forbidden403Page");
    };

    /**
     * マップURL作成ダイアログを閉じたときのハンドラ
     */
    const handleClickCreateMapURLDialogClose = () => {
        setCreateMapURLDialogOpen(false);
    };

    /**
     * 表示するビジネスパートナーIDが変更された時のハンドラ
     */
    const handleChangeBusinessPartnerId = (event: any) => {
        setSelectedBusinessPartnerInfo(businessPartnerInfoList.find(businessPartnerInfo =>
            businessPartnerInfo.businessPartnerId === event.target.value));
        let portLatLonListParam = createPortLatLonList(event.target.value);
        let center = calculateMapCenterPoint(portLatLonListParam);
        if (portLatLonListParam.length === 1) {
            mapRef.current?.flyTo(
                {
                    center: [portLatLonListParam[0].longitude, portLatLonListParam[0].latitude],
                    duration: 2000,
                    zoom: 15
                });
        } else {
            mapRef.current?.fitBounds(
                [
                    [center.minLng, center.minLat],
                    [center.maxLng, center.maxLat]
                ],
                { padding: 40, duration: 1000 }
            );
        }
    };

    /**
     * ポートの緯度経度リストを作成する
     */
    const createPortLatLonList = (businessPartnerId: string, portList?: Port[]): {
        longitude: number;
        latitude: number;
    }[] => {
        console.log(businessPartnerId)
        setMapErrorMessage(undefined);

        let filterPortList: Port[] = new Array();
        if (allPortList.length > 0) {
            filterPortList = allPortList.filter(port => port.businessPartnerId
                === businessPartnerId);
        } else if (portList) {
            filterPortList = portList?.filter(port => port.businessPartnerId
                === businessPartnerId);
        };

        const portLatLonListParam: {
            longitude: number;
            latitude: number;
        }[] = new Array();

        for (let port of filterPortList) {
            portLatLonListParam.push(
                {
                    latitude: Number(port.latitude),
                    longitude: Number(port.longitude)
                }
            );
        };
        if (portLatLonListParam.length === 0) {
            setMapErrorMessage("地図情報を取得できませんでした");
        };
        setPortLatLonList(portLatLonListParam);
        return portLatLonListParam;
    }

    /**
     * 地図の中心点を計算する
     */
    const calculateMapCenterPoint = (portLatLonListParam: {
        longitude: number;
        latitude: number;
    }[]): {
        minLat: number;
        maxLat: number;
        minLng: number;
        maxLng: number;
    } => {

        const getBoundsResult: {
            minLat: number,
            maxLat: number,
            minLng: number,
            maxLng: number
        } = geolib.getBounds(portLatLonListParam);
        setMinMaxLanLng(getBoundsResult);
        return getBoundsResult;
    };

    return (
        <div className={classes.root}>

            {/**メニューバーを表示する */}
            <OperationMenuBar
                onChangeDrawerOpen={handleDrawerOpen}
                onChangeDrawerClose={handleDrawerClose}
                open={isOpen}
                title="ドローン位置情報マップ"
            />

            {/** コンテンツ部分 */}
            <main
                className={cx(classes.content, {
                    /** メニューバーがオープン・クローズされたときのスタイルの変更*/
                    [classes.contentShift]: isOpen,
                })}
            >
                <div className={classes.drawerHeader} />
                {
                    mapErrorMessage ? (
                        <Paper className={classes.errorMessage} elevation={1}>
                            <Alert severity="error">{mapErrorMessage}</Alert>
                        </Paper>
                    ) : (undefined)
                }
                {
                    portErrorMessage ? (
                        <Paper className={classes.errorMessage} elevation={1}>
                            <Alert severity="error">{portErrorMessage}</Alert>
                        </Paper>
                    ) : (undefined)
                }
                {
                    geoInformationErrorMessage ? (
                        <Paper className={classes.errorMessage} elevation={1}>
                            <Alert severity="error">{geoInformationErrorMessage}</Alert>
                        </Paper>
                    ) : (undefined)
                }
                {
                    geoInformationByFlightIdErrorMessage ? (
                        <Paper className={classes.errorMessage} elevation={1}>
                            <Alert severity="error">{geoInformationByFlightIdErrorMessage}</Alert>
                        </Paper>
                    ) : (undefined)
                }
                {
                    userErrorMessage ? (
                        <Paper className={classes.errorMessage} elevation={1}>
                            <Alert severity="error">{userErrorMessage}</Alert>
                        </Paper>
                    ) : (undefined)
                }
                <Grid container direction="row" spacing={1} sx={{ marginBottom: 2 }}>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch
                                checked={isUpdateMap}
                                onChange={() => setUpdateMap(!isUpdateMap)}
                                name="isUpdateMap"
                                color="primary"
                            />}
                            label="MAP自動更新"
                        />
                    </Grid>
                    <Grid item>
                        <FormControl size="small" sx={{ minWidth: 190 }}>
                            <InputLabel id="partnerIdLabel">ビジネスパートナー</InputLabel>
                            <Select
                                label="ビジネスパートナー"
                                value={selectedBusinessPartnerInfo?.businessPartnerId ?? ""}
                                onChange={handleChangeBusinessPartnerId}
                            >
                                {businessPartnerInfoList.map((businessPartnerInfo: BusinessPartnerInfo, i: number) => (
                                    <MenuItem key={i} value={businessPartnerInfo.businessPartnerId}>
                                        {businessPartnerInfo.businessPartnerName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item>
                        <Button color="primary"
                            variant='contained'
                            size='small'
                            onClick={() => setCreateMapURLDialogOpen(true)}
                            sx={{ minHeight: 38, minWidth: 17 }}>
                            <LinkIcon />
                        </Button>
                    </Grid>
                    <Grid item>
                        <ButtonGroup size="small" variant="contained" color="primary" sx={{ minHeight: 38 }}>
                            <Button
                                onClick={(e) => handleChangeMapStyle(MapStyle.neutral)}
                                disabled={mapStyle === MapStyle.neutral}
                            >
                                地形
                            </Button>
                            <Button
                                onClick={(e) => handleChangeMapStyle(MapStyle.satellite)}
                                disabled={mapStyle === MapStyle.satellite}>
                                衛星写真
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="stretch" sx={{ margin: "8px", position: "absolute", width: "auto" }}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", zIndex: 10 }}>
                        <Paper>
                            <Grid container direction="row" alignItems='center'>
                                <UpdateIcon sx={{ margin: "8px 0px 8px 8px" }} />
                                <Typography variant='subtitle2' sx={{ margin: "8px", lineHeight: "0px" }}>
                                    {getGeoInformationTime}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Hidden only={['sm', 'xs']}>
                        <Grid item lg={12} md={12}>
                            <Grid container
                                lg={3}
                                md={3}
                                sx={{
                                    maxHeight: `calc(100vh - 280px)`
                                }}>
                                {operatingFlightInfoList.length !== 0 ?
                                    <FlightCardListPaper>
                                        {operatingFlightInfoList.map(flightInfo => (
                                            <Grid item key={flightInfo.id} sx={{ zIndex: 40, paddingBottom: "8px" }}>
                                                <FlightCardForDroneGeoInformationView
                                                    flightInfo={flightInfo}
                                                    selectedFlightId={selectedFlightId}
                                                    setSelectedFlightId={setSelectedFlightId} />
                                            </Grid>
                                        ))}
                                    </FlightCardListPaper>
                                    : undefined}
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
                {minMaxLanLng ?
                    <MapView
                        ref={mapRef}
                        initialViewState={portLatLonList.length === 1 ? {
                            longitude: portLatLonList[0].longitude,
                            latitude: portLatLonList[0].latitude,
                            zoom: 15,
                        } :
                            {
                                bounds:
                                    [
                                        [minMaxLanLng.minLng, minMaxLanLng.minLat],
                                        [minMaxLanLng.maxLng, minMaxLanLng.maxLat]],
                                fitBoundsOptions: {
                                    padding: 60
                                }
                            }

                        }
                        mapStyle={geoConfigMapStyle}
                        style={styleProps as React.CSSProperties}
                        onError={error => {
                            console.log(error);
                            setMapErrorMessage("地図情報を取得できませんでした");
                        }}>
                        {geoInformationListPerDroneIdList.map((geoInformationListPerDroneIdId, droneIndex) => (
                            (geoInformationListPerDroneIdId.geoInformationList).map((geoInformation, locationIndex) => (
                                <Marker
                                    key={`marker-${droneIndex}-${locationIndex}`}
                                    longitude={geoInformation.longitude}
                                    latitude={geoInformation.latitude}
                                    onClick={e => {
                                        e.originalEvent.stopPropagation();
                                        setDronePopupInfo(geoInformation)
                                    }}
                                    style={{ zIndex: geoInformationListPerDroneIdId.geoInformationList.length - locationIndex }}
                                >
                                    {handleDisplayLocationIcon(locationIndex, false)}
                                </Marker>
                            ))
                        ))}
                        {lastGeoInformationPerDroneIdList.map((lastGeoInformationPerDroneId, droneIndex) => (
                            <Marker
                                key={`marker-${droneIndex}`}
                                longitude={lastGeoInformationPerDroneId.geoInformation.longitude}
                                latitude={lastGeoInformationPerDroneId.geoInformation.latitude}
                                onClick={e => {
                                    e.originalEvent.stopPropagation();
                                    setDronePopupInfo(lastGeoInformationPerDroneId.geoInformation)
                                }}
                            >
                                {handleDisplayLocationIcon(0, true)}
                            </Marker>
                        ))}

                        {selectedFlightId && geoInformationListPerFlightId.length !== 0 ?
                            geoInformationListPerFlightId.map((geoInformation, index) => (

                                index !== geoInformationListPerFlightId.length - 1 ?
                                    <Marker
                                        key={`marker-${index}`}
                                        longitude={geoInformation.longitude}
                                        latitude={geoInformation.latitude}
                                        onClick={e => {
                                            e.originalEvent.stopPropagation();
                                            setDronePopupInfo(geoInformation)
                                        }}
                                    >
                                        {handleDisplayLocationIconPerFlightId(index)}
                                    </Marker> : undefined
                            ))
                            : undefined}

                        {allPortList.map((port, index) => (
                            <Marker
                                key={`marker-${index}`}
                                longitude={Number(port.longitude)}
                                latitude={Number(port.latitude)}
                                onClick={e => {
                                    e.originalEvent.stopPropagation();
                                    setPortPopupInfo(port)
                                }}
                            >
                                {mapStyle === MapStyle.neutral ?
                                    <FlagIcon style={{
                                        color: '#000000',
                                        stroke: "#ffffff",
                                        strokeWidth: "0px"
                                    }} />
                                    : undefined}
                                {mapStyle === MapStyle.satellite ?
                                    <FlagIcon style={{
                                        color: '#ffffff',
                                        stroke: "#000000",
                                        strokeWidth: "0.5px"
                                    }} /> : undefined}
                            </Marker>
                        ))}
                        {displayDronePopupInfo()}
                        {displayPortPopupInfo()}
                        <NavigationControl style={{ margin: "25px" }} />
                    </MapView>
                    : undefined}

                <Dialog open={!isAutoUpdateMap}>
                    <DialogTitle>
                        MAP自動更新停止中
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            画面がアクティブになると更新を再開します。<br />
                            {"最終更新日時：" + getGeoInformationTime}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <CreateMapURLDialog
                    isCreateMapURLDialogOpen={isCreateMapURLDialogOpen}
                    handleClickCreateMapURLDialogClose={handleClickCreateMapURLDialogClose} />
            </main>
        </div >
    );
}