import DoneIcon from '@mui/icons-material/Done';
import { Alert, Button, Chip, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { OrderInfo, ReceiveStatus } from 'adoms-common-lib';
import React from 'react';
import { useLocation, useSearchParams } from "react-router-dom";
import useInterval from 'use-interval';
import { LoadingMark } from '../../components/atoms/LoadingMark';
import ApplicationTooBar from '../../components/molecule/ApplicationToolBar';
import { DroneMapView } from '../../components/molecule/DroneMapView';
import { EditDialog } from '../../components/molecule/EditDialog';
import { OperationHistoryTimeline } from '../../components/molecule/OperationHistoryTimeline';
import { OrderInfoDisplayPaper } from '../../components/molecule/OrderInfoDisplayPaper';

import { ReceiveStatusStepper } from '../../components/molecule/ReceiveStatusStepper';
import { APIConnector } from '../../connector/APIConnector';
import { QRCodeDialog } from '../../components/molecule/QRCodeDialog';

const OrderViewPaper = styled(Paper)(({ theme }) => ({
    borderRadius: "10px",
    margin: "0px 10px",
    padding: "10px"
}));

export default function OrderView(props: any) {
    /**
     * 確認番号をキーにして画面を表示
     */
    const location = useLocation();

    /**
     * 画面表示用の配送依頼
     */
    const [searchParams] = useSearchParams();
    const [order, setOrder] = React.useState<OrderInfo | undefined>(undefined);
    const [isDisplayQRCode, setDisplayQRCode] = React.useState<boolean>(false);
    const [isDisplayOrderEditDialog, setDisplayOrderEditDialog] = React.useState<boolean>(false);
    const [statusChangeError, setStatusChangeError] = React.useState<string>();
    const [error, setError] = React.useState<string>();
    const [tenantID, setTenantID] = React.useState<string>("");
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(true);

    const fetchOrderData = async () => {
        const orderIDParam = location.state === null ? searchParams.get("orderid") : location.state.id as string
        const tenantIDParam = location.state === null ? searchParams.get("tenantid") : location.state.id as string
        let c: APIConnector = APIConnector.instance;
        if (orderIDParam && tenantIDParam) {
            await c.getOrderForCustomer(orderIDParam, tenantIDParam).then(orderInfo => {
                setOrder(orderInfo);
                setDisplayLoadingMark(false);
            }).catch((error) => {
                console.log(error);
                setDisplayLoadingMark(false);
                setError("配送情報が取得できませんでした");
            });
            setTenantID(tenantIDParam);
        } else {
            setDisplayLoadingMark(false);
            setError("配送情報取得に必要な値が指定されていないため、情報を表示できません")
        };
    };

    /**
     * 5秒間隔で配送情報を取得する
     */
    useInterval(() => {
        if (document.hasFocus()) {
            fetchOrderData();
        }
    }, 5000);

    const handleChangeStatusToReceived = async () => {
        let c: APIConnector = APIConnector.instance;
        if (order && tenantID) {
            const requestOrder = structuredClone(order);
            requestOrder.receiveStatus = ReceiveStatus.Received;
            setDisplayLoadingMark(true);
            await c.putOrderChangeForCustomer(requestOrder, tenantID).then((orderInfo) => {
                setStatusChangeError(undefined);
                setOrder(orderInfo);
                setDisplayLoadingMark(false);
            }).catch((error) => {
                setDisplayLoadingMark(false);
                console.log(error);
                setStatusChangeError("配送ステータスが更新できませんでした。");
            });
        };
    };

    return (
        <div>
            <Grid container justifyContent="center" xs={12} spacing={1}>
                <Grid item xs={12} sm={12} >
                    <ApplicationTooBar label="配送状況" />
                </Grid>
                {error ?
                    <Grid item xs={12} sm={12} >
                        <OrderViewPaper elevation={2}>
                            <Alert severity="error">{error}</Alert>
                        </OrderViewPaper>
                    </Grid>
                    :
                    <React.Fragment>
                        <Grid item xs={12} sm={12} >
                            <div>
                                <OrderViewPaper elevation={2}>
                                    <Grid container justifyContent="center" spacing={1}>
                                        {order?.receiveStatus !== ReceiveStatus.Cancel ?
                                            <Grid item xs={12} sm={12} >
                                                <ReceiveStatusStepper order={order} />
                                            </Grid> : undefined}

                                        {order?.receiveStatus === ReceiveStatus.WaitingForPickup ?
                                            (<Grid item xs={12} sm={12}>
                                                <Alert severity="info" style={{ textAlign: "left" }}>
                                                    お荷物は現在、営業所への集荷待ちです。お届けまでしばらくお待ちください。
                                                </Alert>
                                            </Grid>) : null
                                        }
                                        {order?.receiveStatus === ReceiveStatus.DepartForPort ?
                                            order?.businessPartnerId === "OKINAWA_BLOOD" ?
                                                (<Grid item xs={12} sm={12}>
                                                    <Alert severity="info" style={{ textAlign: "left" }}>
                                                        お荷物は現在、血液センターを出発しました。
                                                    </Alert>
                                                </Grid>) :
                                                (<Grid item xs={12} sm={12}>
                                                    <Alert severity="info" style={{ textAlign: "left" }}>
                                                        お荷物は現在、出発ポートにむけて出発しました。お届けまでしばらくお待ちください。
                                                    </Alert>
                                                </Grid>) : null
                                        }
                                        {order?.receiveStatus === ReceiveStatus.Preparation ?
                                            (<Grid item xs={12} sm={12}>
                                                <Alert severity="info" style={{ textAlign: "left" }}>
                                                    お荷物は現在、お届け準備中です。お届けまでしばらくお待ちください。
                                                </Alert>
                                            </Grid>) : null
                                        }
                                        {order?.receiveStatus === ReceiveStatus.Loaded ?
                                            (<Grid item xs={12} sm={12}>
                                                <Alert severity="info" style={{ textAlign: "left" }}>
                                                    お荷物は現在、ドローンでお届け中です。
                                                </Alert>
                                            </Grid>) : null
                                        }
                                        {order?.receiveStatus === ReceiveStatus.Received ?
                                            (<Grid item xs={12} sm={12}>
                                                <Alert severity="info" style={{ textAlign: "left" }}>
                                                    お荷物はお届け済みです。
                                                </Alert>
                                            </Grid>) : null
                                        }
                                        {order?.receiveStatus === ReceiveStatus.Cancel ?
                                            (<Grid item xs={12} sm={12}>
                                                <Alert severity="info" style={{ textAlign: "left" }}>
                                                    お荷物の配送はキャンセルされています。
                                                    お荷物のお預かり後のキャンセルの場合、係員よりご連絡させていただきます。
                                                </Alert>
                                            </Grid>) : null
                                        }
                                        {statusChangeError ?
                                            <Grid item xs={12} sm={12} >
                                                <Alert severity="error">{statusChangeError}</Alert>
                                            </Grid> : undefined
                                        }
                                    </Grid>
                                </OrderViewPaper>
                            </div>
                        </Grid>
                        {/* 沖縄血液製剤実証対応 */}
                        {order?.businessPartnerId === "OKINAWA_BLOOD" ?
                            <Grid item xs={12} sm={12} >
                                <OrderViewPaper elevation={2}>
                                    <Grid container spacing={1} justifyContent={"center"}>
                                        <Grid item alignContent={"center"}>
                                            <Chip label="血液製剤受領後にボタンを押してください" />
                                        </Grid>
                                        <Grid item>
                                            <Button startIcon={<DoneIcon />}
                                                variant="contained"
                                                onClick={handleChangeStatusToReceived}
                                                disabled={order?.receiveStatus === ReceiveStatus.Received || isDisplayLoadingMark}>
                                                血液製剤受領
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </OrderViewPaper>
                            </Grid> : undefined}
                        <Grid item xs={12} sm={12} >
                            <OrderViewPaper elevation={2}>
                                <DroneMapView
                                    order={order}
                                    tenantId={tenantID}
                                />
                            </OrderViewPaper>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <OrderInfoDisplayPaper
                                order={order}
                                setDisplayQRCode={setDisplayQRCode}
                                setDisplayOrderEditDialog={setDisplayOrderEditDialog}
                                customerInfoDisplayFlg={false} />
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                            <OrderViewPaper elevation={2}>
                                <OperationHistoryTimeline
                                    tenantId={tenantID}
                                    order={order} />
                            </OrderViewPaper>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
            <QRCodeDialog
                order={order}
                isDisplayQRCode={isDisplayQRCode}
                setDisplayQRCode={setDisplayQRCode}
            />
            <EditDialog
                order={order}
                setOrder={setOrder}
                tenantID={tenantID}
                isDisplayOrderEditDialog={isDisplayOrderEditDialog}
                setDisplayOrderEditDialog={setDisplayOrderEditDialog}
            />
            <LoadingMark isDisplayLoadingMark={isDisplayLoadingMark} />
        </div >
    );
}