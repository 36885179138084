import { CustomerURLInformation, FlightInfo, GeoInformation, MapURLInformation, OperationHistory, OrderInfo, parseOrderInfo, Port } from 'adoms-common-lib';
import axios from 'axios';

export class APIConnector {
  /** インスタンス */
  private static _instance: APIConnector;

  /** インスタンスの取得 */
  public static get instance(): APIConnector {
    // _inctanceが存在しない場合に、new Hoge()を実行する。
    if (!this._instance) {
      console.log("create ConfigInstance");
      this._instance = new APIConnector();
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  /**
   * カスタマー用ポートリストを取得
   */
  public async getPortListForCustomer(businessPartnerId: string, tenantId: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_PORTSFORCUSTOMER as string +
      "?businessPartnerId=" +
      businessPartnerId +
      "&tenantId=" +
      tenantId
    ).then(
      function (response): Port[] {
        var portlist = new Array<Port>();
        console.log("getPortList response:" + JSON.stringify(response.data));
        for (const port of response.data) {
          portlist.push(port);
        }
        return portlist.sort((a: Port, b: Port) => { return (a.sortkey < b.sortkey) ? 1 : -1 });
      }
    ).catch((error) => { // 通信エラーが発生したら
      throw error;
    });
  }

  /**
   * オーダーを取る
   * @param order 
   */
  public async putOrder(order: OrderInfo, tenantId: string, receiptImageBase64?: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    console.log("order_request" + JSON.stringify(order));
    let body = {
      orderInfo: order,
      receiptImageBase64: receiptImageBase64,
      tenantId: tenantId
    }
    return await client.post(process.env.REACT_APP_API_PUTORDER as string, body).then(
      function (res): OrderInfo {
        console.log("OrderInfo:" + res);
        return parseOrderInfo(res.data);
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * 配送情報を取得するハンドラ（カスタマー用のフロント向け）
   * @param orderID
   */
  public async getOrderForCustomer(confirmationNumber: string, tenantId: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GETORDERFORCUSTOMER as string + "/" + tenantId + "/" + confirmationNumber
    ).then(
      function (res): OrderInfo {
        return res.data as OrderInfo;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
  * オーダーIDを検索条件とし、
  * オーダーIDに紐づくフライトIDごとのドローンの位置情報を取得する
  * @returns key:flightId value:GeoInformation[]
  */
  public async getGeoInformationByOrderId(orderId: string, tenantId: string): Promise<Map<string, GeoInformation[]>> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GEOINFORMATION_ORDERID + "?orderId=" + orderId + "&tenantId=" + tenantId
    ).then(
      function (res): Map<string, GeoInformation[]> {
        return new Map(Object.entries(res.data));
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * マップのURLIDを検索条件とし、マップURL情報を取得する
  * @returns mapURLInformation
  */
  public async getMapUrlInformation(urlId: string): Promise<MapURLInformation> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_MAPURLINFORMATION + "/" + urlId
    ).then(
      function (res): MapURLInformation {
        return res.data as MapURLInformation;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  };

  /**
  * マップのURLIDを検索条件とし、ドローン位置情報を取得する
  * @returns mapURLInformation
  */
  public async getDroneGeoInfoByURLId(urlId: string): Promise<Map<string, GeoInformation[]>> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GEOINFORMATION_DRONE_URLID + "/" + urlId
    ).then(
      function (res): Map<string, GeoInformation[]> {
        return new Map(Object.entries(res.data));
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  };

  /**
  * マップのURLIDを検索条件とし、フライト一覧を取得する
  * @returns mapURLInformation
  */
  public async getFlightListByURLId(urlId: string): Promise<FlightInfo[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GETFLIGHTLISTBYURLID + "?urlId=" + urlId
    ).then(
      function (res): FlightInfo[] {
        let flightInfoList = new Array();
        for (let flightInfo of res.data) {
          flightInfoList.push(flightInfo);
        };
        console.log(flightInfoList)

        return flightInfoList;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  };

  /**
  * 特定の配送依頼用サイトURL情報を取得する
  */
  public async getCustomerURLInformation(urlId: string): Promise<CustomerURLInformation> {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_CUSTOMER_URL_INFO as string + "\\" + urlId
    ).then(
      function (res): CustomerURLInformation {
        return res.data as CustomerURLInformation;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * 特定の配送依頼用サイトURL情報を取得する
  */
  public async putOrderChangeForCustomer(orderInfo: OrderInfo, tenantId: string): Promise<OrderInfo> {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    let body = {
      orderInfo: orderInfo,
      tenantId: tenantId
    };

    return await client.post(
      process.env.REACT_APP_API_PUTORDERCHANGEFORCUSTOMER as string, body
    ).then(
      function (res) {
        return res.data as OrderInfo;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * カスタマーサイト用情報操作履歴を取得する
  * @param tenantId OrderId
  */
  public async getHistoryForCustomer(tenantId: string, orderId: string) {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
      },
      responseType: 'json'
    });

    return await client.get(process.env.REACT_APP_API_GETHISTORYFORCUSTOMER + "?tenantId=" + tenantId + "&orderId=" + orderId).then(
      function (res): OperationHistory[] {
        let operationHistoryList = new Array<OperationHistory>();
        for (const operationHistory of res.data) {
          operationHistoryList.push(operationHistory);
        }
        return operationHistoryList;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }
}
