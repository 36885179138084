import { MapView } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { load } from "@loaders.gl/core";
import { KMLLoader } from "@loaders.gl/kml";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FlagIcon from '@mui/icons-material/Flag';
import FlightIcon from '@mui/icons-material/Flight';
import { Alert, Button, Grid, Paper, SvgIcon, Typography } from "@mui/material";
import { lighten } from '@mui/system';
import { Display, FlightInfo, GeoInformation, MapStyle, Port } from "adoms-common-lib";
import { Auth, Geo } from "aws-amplify";
import type { FeatureCollection } from 'geojson';
import * as geolib from "geolib";
import React, { useEffect, useMemo } from 'react';
import { Layer, LineLayer, Marker, NavigationControl, Popup, Source, } from 'react-map-gl';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ReactComponent as DroneIcon } from "../../asset/icons/quadcopter.svg";
import ApplicationTooBar from "../../components/molecule/ApplicationToolBar";
import { APIConnector } from "../../connector/APIConnector";

type AmplifyGeoConfig = {
    geo?: {
        amazon_location_service?: AmplifyGeoOptions;
        AmazonLocationService?: AmplifyGeoOptions;
    };
};

type AmplifyGeoOptions = {
    maps?: {
        neutral: string,
        satellite: string
    };
    region: string;
};

type GeoInformationPerDroneId = {
    droneRegistrationId: string,
    geoInformationList: GeoInformation[]
};

export default function GeoInformationView() {
    const amplifyConfig = Geo.configure() as AmplifyGeoConfig;
    Auth.configure();
    const geoConfig = useMemo(
        () =>
            amplifyConfig.geo?.amazon_location_service ??
            amplifyConfig.geo?.AmazonLocationService ??
            ({} as AmplifyGeoOptions),
        [amplifyConfig]
    );
    const styleProps = useMemo(
        () => ({
            height: `calc(100vh - 90px)`,
            position: 'relative',
            width: '100%',
        }),
        []
    );

    const location = useLocation();
    const [geoConfigMapStyle, setGeoConfigMapStyle] = React.useState<string | undefined>(geoConfig.maps?.neutral);
    const [mapErrorMessage, setMapErrorMessage] = React.useState<string | undefined>(undefined);
    const [flightErrorMessage, setFlightErrorMessage] = React.useState<string | undefined>();
    const [URLId, setURLId] = React.useState<string | undefined>(undefined);
    const [searchParams] = useSearchParams();
    const [minMaxLanLng, setMinMaxLanLng] = React.useState<{
        minLat: number,
        maxLat: number,
        minLng: number,
        maxLng: number
    } | undefined>(undefined);
    const [portListForDisplayMap, setPortListForDisplayMap] = React.useState<Port[] | undefined>(undefined);
    const [portPopupInfo, setPortPopupInfo] = React.useState<Port | undefined>();
    const [dronePopupInfo, setDronePopupInfo] = React.useState<GeoInformation | undefined>();
    const [mapStyle, setMapStyle] = React.useState<MapStyle>(MapStyle.neutral);
    const [geoInformationListPerDroneIdList, setGeoInformationListPerDroneIdList] = React.useState<GeoInformationPerDroneId[]>([]);
    const [flightInfoList, setflightInfoList] = React.useState<FlightInfo[]>([]);
    const [displayFlightInfo, setDisplayFlightInfo] = React.useState<FlightInfo | undefined>();
    const [routeData, setRouteData] = React.useState<FeatureCollection | undefined>();
    const [businessPartnerId, setbusinessPartnerId] = React.useState<string | undefined>();

    /**
     * 画面表示時に地図の中心となる点を算出する
     * また、ドローンの位置情報を取得する
     */
    useEffect(() => {
        let allPortList: Port[];
        const fetchMapUrlInformation = async () => {
            try {
                setMapErrorMessage(undefined);

                // URLIDを取得する
                const urlId = location.state === null ? searchParams.get("id") : location.state.id as string;
                console.log(urlId);

                if (!urlId) {
                    console.error("urlIdが存在しません");
                    throw new Error("URLが正しくありません");
                };
                setURLId(urlId);

                const apiConnector: APIConnector = APIConnector.instance;
                let mapUrlInformation = await apiConnector.getMapUrlInformation(urlId);

                if (!mapUrlInformation) {
                    console.error("mapURLInformationが存在しません");
                    throw new Error("URLが正しくありません");
                } else if (!mapUrlInformation.expirationDateTime) {
                    console.error("expirationDateTimeが存在しません");
                    throw new Error("URLが正しくありません");
                } else if (!mapUrlInformation.businessPartnerId) {
                    console.error("businessPartnerIdが存在しません");
                    throw new Error("URLが正しくありません");
                };

                setbusinessPartnerId(mapUrlInformation.businessPartnerId);

                let getPortListForCustomer = apiConnector.getPortListForCustomer(mapUrlInformation.businessPartnerId, mapUrlInformation.tenantId);
                let getFlightListByURLID = apiConnector.getFlightListByURLId(urlId);

                // マップURL情報を取得する
                await Promise.all([getPortListForCustomer, getFlightListByURLID]).then(async value => {
                    allPortList = value[0];
                    setflightInfoList(value[1]);
                    console.log(mapUrlInformation);
                }).catch((error) => {
                    console.log(error.message);
                    if (error.response
                        && error.response.data.reason == "mapURL is expired") {
                        throw new Error("URLの有効期限が切れています");
                    }
                    throw new Error("地図情報を取得できませんでした");
                });

                // ポート情報を取得し、地図の中心を算出する
                const filterPortList = allPortList.filter(port => port.businessPartnerId
                    === mapUrlInformation.businessPartnerId);
                setPortListForDisplayMap([...filterPortList]);
                const getCenterParam: {
                    longitude: number;
                    latitude: number;
                }[] = new Array();
                for (let port of filterPortList) {
                    getCenterParam.push(
                        {
                            latitude: Number(port.latitude),
                            longitude: Number(port.longitude)
                        }
                    );
                };
                const getBoundsResult: {
                    minLat: number,
                    maxLat: number,
                    minLng: number,
                    maxLng: number
                } = geolib.getBounds(getCenterParam);
                console.log(getBoundsResult)
                setMinMaxLanLng(getBoundsResult);

                // 初回のドローン位置情報を取得する
                fetchDroneData(urlId);

            } catch (error) {
                const e = error as Error;
                console.error(e.message);
                setMapErrorMessage(e.message);
            };
        };
        fetchMapUrlInformation();
    }, []);

    /**
     * 5秒間隔でドローンの位置情報取得を呼び出す
     */
    useEffect(() => {
        console.log(geoInformationListPerDroneIdList);

        if (URLId) {
            let timeoutId: number | undefined
            timeoutId = window.setTimeout(() => fetchDroneData(URLId), 5 * 1000);
            return () => {
                if (typeof timeoutId !== "undefined") {
                    window.clearTimeout(timeoutId);
                };
            };
        };
    }, [geoInformationListPerDroneIdList]);

    useEffect(() => {
        const dataload = async () => {
            const kmlFilePath = "./kml/geoInformationView/"
                + businessPartnerId + ".kml";
            //KMLデータを読み込む
            const res = await load(kmlFilePath, KMLLoader);
            //KMLローダーで読み込んだデータはGeoJSON形式に変換されている
            console.log(res);
            //読み込んだデータを保存
            setRouteData(res);
        };
        dataload();
    }, [businessPartnerId]);

    /**
     * ドローンの位置情報を取得する
     */
    const fetchDroneData = (urlId: string) => {
        const c: APIConnector = APIConnector.instance;
        const fetchMapUrlInformation = async () => {
            // マップURL情報を取得する
            await c.getDroneGeoInfoByURLId(urlId).then((geoInformationMap: Map<string, GeoInformation[]>) => {
                console.log(geoInformationMap);
                let newGeoInformationListPerDroneIdList: GeoInformationPerDroneId[] = new Array();
                geoInformationMap.forEach(function (geoInformationList, droneRegistrationId) {
                    let geoInformationListPerDroneId: GeoInformationPerDroneId = {
                        droneRegistrationId: droneRegistrationId,
                        geoInformationList: geoInformationList
                    };
                    newGeoInformationListPerDroneIdList.push(geoInformationListPerDroneId);
                });
                setGeoInformationListPerDroneIdList(newGeoInformationListPerDroneIdList);
            }).catch((error) => {
                console.log(error);
                if (error.response
                    && error.response.data.reasonCode == "DGC0002"
                    && error.response.data.reason == "mapURL is expired") {
                    setMapErrorMessage("URLの有効期限が切れているため、ドローンの位置情報を取得できません");
                } else {
                    setMapErrorMessage("地図情報を取得できませんでした");
                };
            });
        };

        if (document.hasFocus()) {
            // 画面がアクティブの場合
            fetchMapUrlInformation();
        } else {
            setGeoInformationListPerDroneIdList([...geoInformationListPerDroneIdList]);
        };
    };

    /**
     * ドローンアイコンがクリックされた時のハンドラ
     * @param e 
     * @param geoInformation 
     */
    const clickDroneIcon = (e: mapboxgl.MapboxEvent<MouseEvent>, geoInformation: GeoInformation) => {
        e.originalEvent.stopPropagation();
        setDronePopupInfo(geoInformation);

        let displayFlightInfo = flightInfoList.find(flightInfo =>
            flightInfo.id === geoInformation.flightId);
        console.log(displayFlightInfo)

        if (!displayFlightInfo && URLId) {
            // 飛行中のフライト一覧に該当するフライトが存在しなかった場合、最新のフライト情報を取得する
            const apiConnector: APIConnector = APIConnector.instance;
            const fetchFlightInfoList = async () => {
                await apiConnector.getFlightListByURLId(URLId).then((flightInfoList) => {
                    displayFlightInfo = flightInfoList.find(flightInfo =>
                        flightInfo.id === geoInformation.flightId);
                    setflightInfoList(flightInfoList);
                    setDisplayFlightInfo(displayFlightInfo);
                    setFlightErrorMessage(undefined);
                }).catch((error) => {
                    console.log(error);
                    setFlightErrorMessage("フライト情報が取得できませんでした");
                });
                fetchFlightInfoList();
            }
        };
        setDisplayFlightInfo(displayFlightInfo);
    };

    /**
     * ポート用ポップアップを表示させる
     */
    const displayPortPopupInfo = () => {
        if (portPopupInfo) {
            return (
                <Popup
                    style={{ maxWidth: "180px", zIndex: 300 }}
                    longitude={Number(portPopupInfo.longitude)}
                    latitude={Number(portPopupInfo.latitude)}
                    anchor="bottom"
                    onClose={() => setPortPopupInfo(undefined)}>
                    <Grid container direction="row">
                        <Grid item>
                            <FlagIcon fontSize="small" sx={{ marginRight: "2px" }} />
                        </Grid>
                        <Grid item>
                            <Typography>{portPopupInfo.name}</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle2"
                        sx={{ overflowWrap: "break-word" }}>
                        {portPopupInfo.latitude + "," + portPopupInfo.longitude}</Typography>
                </Popup>
            );
        };
    };

    /**
     * ドローン用ポップアップを表示させる
     */
    const displayDronePopupInfo = () => {
        if (dronePopupInfo) {
            let sendTime = Display.getTimeMiniteSecondStringFromISO8601(dronePopupInfo.sendTime);
            return (
                <Popup
                    longitude={dronePopupInfo.longitude}
                    latitude={dronePopupInfo.latitude}
                    anchor="bottom"
                    onClose={() => closeDronePopUp()}
                    style={{ zIndex: 300 }}>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                        {flightErrorMessage ?
                            <Grid item>
                                <Alert severity="error" sx={{ marginBottom: 1, fontSize: '0.8rem' }}>{flightErrorMessage}</Alert>
                            </Grid>
                            :
                            <Grid item >
                                <Grid container direction="row">
                                    <Grid item >
                                        <Typography variant="subtitle2" color="textPrimary" component="div" sx={{ maxWidth: "90px" }}>
                                            {displayFlightInfo?.departure.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FlightIcon
                                            fontSize='small'
                                            sx={{
                                                transform: "rotate(90deg)",
                                                margin: "0px 5px"
                                            }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" color="textPrimary" component="div" sx={{ maxWidth: "90px" }}>
                                            {displayFlightInfo?.arrival.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item>
                            <Typography variant="caption" sx={{ display: 'block' }}>{sendTime + "時点"}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption"
                                sx={{ overflowWrap: "break-word", display: 'block' }}>
                                {dronePopupInfo.latitude + "," + dronePopupInfo.longitude}</Typography>
                        </Grid>
                    </Grid>
                </Popup>
            );
        };
    };

    /**
     * ドローンのポップアップが閉じられた時ノハンドラ
     */
    const closeDronePopUp = () => {
        setDronePopupInfo(undefined);
        setDisplayFlightInfo(undefined);
    }

    /**
     * 地図の表示形式が変更された時のハンドラ
     * @param mapStyle 
     */
    const handleChangeMapStyle = (mapStyle: MapStyle) => {
        setMapStyle(mapStyle);
        if (mapStyle === MapStyle.neutral) {
            setGeoConfigMapStyle(geoConfig.maps?.neutral);
        } else if (mapStyle === MapStyle.satellite) {
            setGeoConfigMapStyle(geoConfig.maps?.satellite);
        };
    };

    /**
     * ドローンの経路（マーカー）の色をグラデーションで表示する。
     * 先頭のマーカーはドローンのアイコンを表示させる。
     * @param locationIndex 
     */
    const handleDisplayLocationIcon = (locationIndex: number) => {
        let colorBrightness = 0;

        if (locationIndex === 0) {
            colorBrightness = 0;
        } else if (locationIndex === 1 || locationIndex === 2) {
            colorBrightness = 0.2;
        } else if (locationIndex === 3 || locationIndex === 4) {
            colorBrightness = 0.3;
        } else if (locationIndex === 5 || locationIndex === 6) {
            colorBrightness = 0.4;
        } else {
            colorBrightness = 0.5;
        };

        if (locationIndex === 0) {
            if (mapStyle === MapStyle.satellite) {
                return (
                    <SvgIcon
                        style={{
                            color: "#ffffff",
                            stroke: "#000000",
                            strokeWidth: "0.5px",
                        }}>
                        <DroneIcon />
                    </SvgIcon>
                );
            } else {
                return (
                    <SvgIcon
                        style={{
                            stroke: "#ffffff",
                            strokeWidth: "0.5px"
                        }}>
                        <DroneIcon />
                    </SvgIcon>
                );
            }
        } else {
            return (
                <FiberManualRecordIcon
                    style={{ color: lighten("#797979", colorBrightness), fontSize: 10 }} />
            );
        };
    };

    const handleDisplayPortIcon = (port: Port) => {
        if (displayFlightInfo?.arrival.id === port.id
            || displayFlightInfo?.departure.id === port.id) {
            return (
                <FlagIcon style={{
                    color: '#ff4500',
                    stroke: "#ffffff",
                    strokeWidth: "0px"
                }} />
            );
        } else {
            if (mapStyle === MapStyle.neutral) {
                return (
                    <FlagIcon style={{
                        color: '#000000',
                        stroke: "#ffffff",
                        strokeWidth: "0px"
                    }} />
                );
            } else if (mapStyle === MapStyle.satellite) {
                return (
                    <FlagIcon style={{
                        color: '#ffffff',
                        stroke: "#000000",
                        strokeWidth: "0.5px"
                    }} />
                );
            };
        };
    };

    const lineLayerStyle: LineLayer = {
        id: 'line',
        type: 'line',
        paint: {
            "line-color": "#ea9245",
            "line-width": 3
        }
    };

    return (
        <div >
            <Grid container justifyContent="center" xs={12} spacing={1}>
                <Grid item xs={12} sm={12} >
                    <ApplicationTooBar label="ドローン位置情報マップ" />
                </Grid>
                {
                    mapErrorMessage ?
                        <Grid item xs={12} sm={12}>
                            <Paper elevation={1} sx={{ padding: 1 }}>
                                <Alert severity="error">{mapErrorMessage}</Alert>
                            </Paper>
                        </Grid> : undefined
                }
                {
                    minMaxLanLng ?
                        <Grid item xs={12} sm={12} >
                            <Paper sx={{ padding: 1 }}>
                                <MapView
                                    initialViewState={portListForDisplayMap?.length === 1 ?
                                        {
                                            longitude: Number(portListForDisplayMap[0].longitude),
                                            latitude: Number(portListForDisplayMap[0].latitude),
                                            zoom: 15,
                                        }
                                        : {
                                            bounds:
                                                [
                                                    [minMaxLanLng.minLng, minMaxLanLng.minLat],
                                                    [minMaxLanLng.maxLng, minMaxLanLng.maxLat]],
                                            fitBoundsOptions: {
                                                padding: 40
                                            }
                                        }}
                                    mapStyle={geoConfigMapStyle}
                                    style={styleProps as React.CSSProperties}
                                    onError={error => {
                                        console.log(error);
                                        setMapErrorMessage("地図情報を取得できませんでした");
                                    }}>
                                    {portListForDisplayMap?.map((port, index) => (
                                        <Marker
                                            key={`marker-${index}`}
                                            longitude={Number(port.longitude)}
                                            latitude={Number(port.latitude)}
                                            onClick={e => {
                                                e.originalEvent.stopPropagation();
                                                setPortPopupInfo(port)
                                            }}
                                        >
                                            {handleDisplayPortIcon(port)}
                                        </Marker>
                                    ))}
                                    {geoInformationListPerDroneIdList.map((geoInformationListPerDroneId, droneIndex) => (
                                        (geoInformationListPerDroneId.geoInformationList).map((geoInformation, locationIndex) => (
                                            <Marker
                                                key={`marker-${droneIndex}-${locationIndex}`}
                                                longitude={geoInformation.longitude}
                                                latitude={geoInformation.latitude}
                                                onClick={e => {
                                                    clickDroneIcon(e, geoInformation)
                                                }}
                                                style={{ zIndex: geoInformationListPerDroneId.geoInformationList.length - locationIndex }}
                                            >
                                                {handleDisplayLocationIcon(locationIndex)}
                                            </Marker>
                                        ))
                                    ))}
                                    {displayPortPopupInfo()}
                                    {displayDronePopupInfo()}
                                    {mapStyle === MapStyle.satellite ?
                                        <Button
                                            sx={{ margin: "10px", minWidth: "0px" }}
                                            size='small'
                                            variant="contained"
                                            onClick={(e) => handleChangeMapStyle(MapStyle.neutral)}
                                        >
                                            地形
                                        </Button>
                                        :
                                        <Button
                                            sx={{ margin: "10px", minWidth: "0px" }}
                                            size='small'
                                            variant="contained"
                                            onClick={(e) => handleChangeMapStyle(MapStyle.satellite)}
                                        >
                                            衛星
                                        </Button>
                                    }
                                    {routeData ?
                                        <Source id="route" type="geojson" data={routeData}>
                                            <Layer {...lineLayerStyle} />
                                        </Source> : undefined
                                    }
                                    <NavigationControl style={{ margin: "10px" }} />
                                </MapView>
                            </Paper>
                        </Grid> :
                        undefined
                }
            </Grid>
        </div>
    );
};