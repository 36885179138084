/**
 * 位置情報データを表すデータ型
 */
export interface GeoInformation {
    //フライトID
    flightId: string,
    //時間
    sendTime: string
    //ドローンID
    droneId: string,
    //緯度
    latitude: number,
    //経度
    longitude: number,
    //高度
    altitude: number,
    //位置情報の送信方法
    transferMethod?: TransferMethod
}

/**
 * MAPスタイル
 */
export const MapStyle = {
    /**
     * 地形
     */
    neutral: "neutral",
    /**
     * 衛星写真
     */
    satellite: "satellite"
} as const;
/**
 * ポートタイプ
 */
export type MapStyle = typeof MapStyle[keyof typeof MapStyle];

/**
 * 位置情報の送信方法
 */
export const TransferMethod = {
    /**
     * 地上波（LTE・直接波等遅延の少ない通信方法を使用して送信されたもの）
     */
    groundWave: "groundWave",
    /**
     * 衛星波
     */
    satellite: "satellite"
} as const;
/**
 * ポートタイプ
 */
export type TransferMethod = typeof TransferMethod[keyof typeof TransferMethod];