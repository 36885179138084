import { Alert, CircularProgress, Grid, Paper, styled, Tooltip, Typography } from "@mui/material";
import { FlightInfo, FlightTimeInformation, WaypointWeatherGeoJson, WeatherGeoJson } from "adoms-common-lib";
import dayjs, { extend } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect } from 'react';
import { changeMinutes10MinuteRoundingOff } from "../../common/weatherUtil";
import { APIConnector } from "../../connector/APIConnector";

const TitlePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    backgroundColor: "#EFEFEF",
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
}));

const AltitudeTitlePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    backgroundColor: "#EFEFEF",
    height: "30px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
}));

const ValuePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
}));

type WaypointWindProps = {
    selectedFlightInfo: FlightInfo | undefined
    displayflightIdByWaypointWind: string | undefined
    setDisplayflightIdByWaypointWind: React.Dispatch<React.SetStateAction<string | undefined>>
    waypointWeatherGeoJson: WaypointWeatherGeoJson | undefined
    setWaypointWeatherGeoJson: React.Dispatch<React.SetStateAction<WaypointWeatherGeoJson | undefined>>
    waypointWindErrorMessage: string | undefined
    setWaypointWindErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
    isDisplayWaypointWindLoading: boolean
    setDisplayWaypointWindLoading: React.Dispatch<React.SetStateAction<boolean>>
    setSelectedFlightEtd: React.Dispatch<React.SetStateAction<string | undefined>>
    selectedFlightEtd: string | undefined
}

export const WaypointWindGrid: React.FC<WaypointWindProps> = (props) => {

    useEffect(() => {
        const getWaypointWeatherGeoJson = async () => {
            if (props.selectedFlightInfo && (props.displayflightIdByWaypointWind !== props.selectedFlightInfo.id
                || props.selectedFlightInfo.etd !== props.selectedFlightEtd)) {
                props.setDisplayflightIdByWaypointWind(undefined);
                let apiConnector: APIConnector = APIConnector.instance;
                let etd10Minutes = changeMinutes10MinuteRoundingOff(
                    dayjs(props.selectedFlightInfo.flightDate + " " + props.selectedFlightInfo.etd));
                props.setDisplayWaypointWindLoading(true);
                await apiConnector.getWeatherGeoJsonByWaypoint(
                    props.selectedFlightInfo.flightDate,
                    etd10Minutes.format("HH:mm"),
                    "2000",
                    50,
                    100,
                    props.selectedFlightInfo.businessPartnerId,
                    props.selectedFlightInfo.departure.id,
                    props.selectedFlightInfo.arrival.id
                ).then(waypointWeatherGeoJson => {
                    props.setDisplayflightIdByWaypointWind(props.selectedFlightInfo!.id);
                    props.setSelectedFlightEtd(props.selectedFlightInfo?.etd);
                    props.setWaypointWindErrorMessage(undefined);
                    props.setDisplayWaypointWindLoading(false);
                    props.setWaypointWeatherGeoJson(waypointWeatherGeoJson);
                    console.log(waypointWeatherGeoJson);
                }).catch(error => {
                    console.log(error);
                    props.setDisplayWaypointWindLoading(false);
                    if (error.response
                        && error.response.data.reasonCode == "WWP0006") {
                        props.setWaypointWindErrorMessage("天候情報を取得できません。24分以降にアクセスしてください。");
                    } else {
                        props.setWaypointWindErrorMessage("気象情報が取得できませんでした");
                    }
                });
            };
        };
        getWaypointWeatherGeoJson();
    }, [props.selectedFlightInfo]);

    const displayAltitudeTitlePaper = (title: string) => {
        return (
            <AltitudeTitlePaper elevation={0}>
                <Typography variant='subtitle2'>{title}</Typography>
            </AltitudeTitlePaper>
        )
    };

    const displayWaypointTitle = (waypointIndex: number) => {
        let returnTitle;
        let tooltipTitle;
        if (waypointIndex === 0) {
            returnTitle = "出発地";
            tooltipTitle = props.selectedFlightInfo?.departure.name;
        } else if (waypointIndex === props.waypointWeatherGeoJson!.geoJsonList[0].featureCollection.features.length - 1) {
            returnTitle = "到着地";
            tooltipTitle = props.selectedFlightInfo?.arrival.name;
        } else {
            returnTitle = "WP" + waypointIndex
        }
        return (
            <TitlePaper elevation={0}>
                <Tooltip title={tooltipTitle}>
                    <Typography variant='subtitle2' whiteSpace={"pre-wrap"}>{returnTitle}</Typography>
                </Tooltip>
            </TitlePaper>
        )
    };

    const displayFlightTimeTitle = (title: string, tooltipTitle?: string) => {
        return (
            <Grid item>
                <TitlePaper elevation={0} sx={{ height: "52px" }}>
                    <Tooltip title={tooltipTitle}>
                        <Typography variant='subtitle2' whiteSpace={"pre"}>{title}</Typography>
                    </Tooltip>
                </TitlePaper>
            </Grid>
        )
    };

    const displayFlightTimeValue = (title: string) => {
        return (
            <Grid item>
                <ValuePaper elevation={0} variant="outlined">
                    <Typography variant='subtitle2' whiteSpace={"pre-wrap"}>{title}</Typography>
                </ValuePaper>
            </Grid>
        )
    };

    const displayEtaValue = (flightTimeInformationList: FlightTimeInformation[]) => {
        let gsFlightTimeTotalSec = 0;
        let etaJsxElement: JSX.Element[] = new Array();
        flightTimeInformationList.map((flightTimeInformation) => {
            gsFlightTimeTotalSec = gsFlightTimeTotalSec + flightTimeInformation.gsFlightTimeTotalSec;
            let etaDayJs = dayjs(props.selectedFlightInfo?.flightDate + " " + props.selectedFlightEtd);
            etaDayJs = etaDayJs.add(gsFlightTimeTotalSec, "seconds");
            etaJsxElement.push(
                <Grid item>
                    <ValuePaper elevation={0} variant="outlined" >
                        <Typography variant='subtitle2' whiteSpace={"pre-wrap"} sx={{ color: "#0000cd", fontWeight: "bold" }}>
                            {etaDayJs.format("HH:mm:ss")}</Typography>
                    </ValuePaper>
                </Grid>
            );
        });
        return etaJsxElement;
    };

    const calcETA = (flightTimeInformationList: FlightTimeInformation[]) => {
        let gsFlightTimeTotalSec = 0;
        flightTimeInformationList.map((flightTimeInformation) => {
            gsFlightTimeTotalSec = gsFlightTimeTotalSec + flightTimeInformation.gsFlightTimeTotalSec;
        });
        let etaDayJs = dayjs(props.selectedFlightInfo?.flightDate + " " + props.selectedFlightEtd);
        etaDayJs = etaDayJs.add(gsFlightTimeTotalSec, "seconds");
        return (<Grid item>
            <ValuePaper elevation={0} variant="outlined" >
                <Typography variant='subtitle2' whiteSpace={"pre-wrap"} sx={{ color: "#0000cd", fontWeight: "bold" }}>{etaDayJs.format("HH:mm:ss")}</Typography>
            </ValuePaper>
        </Grid>);
    };

    const calcGsTotalFlightTime = (flightTimeInformationList: FlightTimeInformation[]) => {
        let gsFlightTimeTotalSec = 0;
        flightTimeInformationList.map((flightTimeInformation) => {
            gsFlightTimeTotalSec = gsFlightTimeTotalSec + flightTimeInformation.gsFlightTimeTotalSec;
        });
        extend(duration);
        let etaDayJs = dayjs.duration(gsFlightTimeTotalSec, "second");
        return (<Grid item>
            <ValuePaper elevation={0} variant="outlined" >
                <Typography variant='subtitle2' whiteSpace={"pre-wrap"} sx={{ color: "#0000cd", fontWeight: "bold" }}>{etaDayJs.format("HH:mm:ss")}</Typography>
            </ValuePaper>
        </Grid>);
    };

    return (
        props.isDisplayWaypointWindLoading ?
            <Grid textAlign={"center"}>
                <CircularProgress />
            </Grid >
            :
            props.selectedFlightInfo && props.waypointWeatherGeoJson && props.waypointWeatherGeoJson.geoJsonList.length > 0
                && props.waypointWeatherGeoJson.flightTimeInformationList.length > 0 ?
                props.waypointWindErrorMessage ?
                    <Grid item xs>
                        <Alert severity="error" >{props.waypointWindErrorMessage}</Alert>
                    </Grid> :
                    // <Grid container height={`calc(100vh - 230px)`} spacing={1}>
                    //     <Grid item container height={`calc((100vh - 260px)/2)`} overflow={"scroll"}>
                    //         <Grid item container spacing={0.5}>
                    //             <Grid item container spacing={1} justifyContent="space-between" wrap={"nowrap"}>
                    //                 <Grid item container direction={"column"} spacing={1} justifyContent="space-between" wrap={"nowrap"}>
                    //                     <Grid item>
                    //                         <AltitudeTitlePaper elevation={0}>
                    //                             <Typography variant='subtitle2'>{changeMinutes10MinuteRoundingOff(
                    //                                 dayjs(props.selectedFlightInfo.flightDate + " " + props.selectedFlightEtd)).format("HH:mm")}</Typography>
                    //                         </AltitudeTitlePaper>
                    //                     </Grid>
                    //                     {props.waypointWeatherGeoJson.geoJsonList[0].featureCollection.features.map((feature, waypointIndex) => (
                    //                         // waypointごとに表示する
                    //                         <Grid item container spacing={1} justifyContent="space-between" wrap={"nowrap"} xs>
                    //                             <Grid item xs>
                    //                                 {displayWaypointTitle(feature.properties!.waypointIndex!)}
                    //                             </Grid>
                    //                         </Grid>
                    //                     ))}
                    //                 </Grid>
                    //                 {props.waypointWeatherGeoJson.geoJsonList.map((waypointWeatherGeoJson, altitudeIndex) => (
                    //                     // 高度ごとに表示する
                    //                     <Grid item container direction={"column"} spacing={1} justifyContent="space-between" wrap={"nowrap"}>
                    //                         <Grid item>
                    //                             {displayAltitudeTitlePaper(waypointWeatherGeoJson.altitude + "m")}
                    //                         </Grid>
                    //                         {waypointWeatherGeoJson.featureCollection.features.map((feature, waypointIndex) => (
                    //                             // waypointごとに表示する
                    //                             <Grid item xs>
                    //                                 <ValuePaper elevation={0} variant="outlined">
                    //                                     <Typography variant='subtitle2' whiteSpace={"pre-wrap"}>
                    //                                         {feature.properties!.windDirection + "deg \n"
                    //                                             + feature.properties!.windSpeed + "m/s"}</Typography>
                    //                                 </ValuePaper>
                    //                             </Grid>
                    //                         ))}
                    //                     </Grid>
                    //                 ))}
                    //             </Grid>
                    //         </Grid>
                    //     </Grid>
                    <Grid container spacing={2}>
                        <Grid item container spacing={1} wrap={"nowrap"}>
                            <Grid item>
                                <TitlePaper elevation={0}>
                                    <Typography variant='subtitle2' whiteSpace={"pre"}>ETA</Typography>
                                </TitlePaper>
                            </Grid>
                            {calcETA(props.waypointWeatherGeoJson.flightTimeInformationList)}
                            <Grid item>
                                <TitlePaper elevation={0}>
                                    <Typography variant='subtitle2' whiteSpace={"pre"}>GS総飛行時間</Typography>
                                </TitlePaper>
                            </Grid>
                            {calcGsTotalFlightTime(props.waypointWeatherGeoJson.flightTimeInformationList)}
                        </Grid>
                        <Grid item container height={`calc(100vh - 260px)`} overflow={"scroll"} spacing={1} direction={"column"}>
                            <Grid item container spacing={1} wrap={"nowrap"}>
                                <Grid item container spacing={1} wrap={"nowrap"} direction={"column"}>
                                    {displayFlightTimeTitle("FROM\n-TO")}
                                    {props.waypointWeatherGeoJson.flightTimeInformationList.map((flightTimeInformation) => (
                                        displayFlightTimeValue(flightTimeInformation.fromWaypointIndex.toString() + "-" + flightTimeInformation.toWaypointIndex.toString())
                                    ))}
                                </Grid>
                                <Grid item container spacing={1} wrap={"nowrap"} direction={"column"}>
                                    {displayFlightTimeTitle("TO到着\n予定時刻", "TOに指定されているWaypointへの到着予定時刻")}
                                    {displayEtaValue(props.waypointWeatherGeoJson.flightTimeInformationList)}
                                </Grid>
                                <Grid item container spacing={1} wrap={"nowrap"} direction={"column"}>
                                    {displayFlightTimeTitle("GS\n飛行時間", "風の影響を考慮した飛行時間")}
                                    {props.waypointWeatherGeoJson.flightTimeInformationList.map((flightTimeInformation) => (
                                        displayFlightTimeValue(flightTimeInformation.gsFlightTime_hhmmss)
                                    ))}
                                </Grid>
                                <Grid item container spacing={1} wrap={"nowrap"} direction={"column"}>
                                    {displayFlightTimeTitle("GS\n(m/s)", "Ground Speed")}
                                    {props.waypointWeatherGeoJson.flightTimeInformationList.map((flightTimeInformation) => (
                                        displayFlightTimeValue(flightTimeInformation.gs_msec.toString())
                                    ))}
                                </Grid>
                                <Grid item container spacing={1} wrap={"nowrap"} direction={"column"}>
                                    {displayFlightTimeTitle("距離\n(m)")}
                                    {props.waypointWeatherGeoJson.flightTimeInformationList.map((flightTimeInformation) => (
                                        displayFlightTimeValue(flightTimeInformation.distance_m.toString())
                                    ))}
                                </Grid>
                                <Grid item container spacing={1} wrap={"nowrap"} direction={"column"}>
                                    {displayFlightTimeTitle("Course\n(deg)", "ドローンが進む方向")}
                                    {props.waypointWeatherGeoJson.flightTimeInformationList.map((flightTimeInformation) => (
                                        displayFlightTimeValue(flightTimeInformation.course_deg.toString())
                                    ))}
                                </Grid>
                                <Grid item container spacing={1} wrap={"nowrap"} direction={"column"}>
                                    {displayFlightTimeTitle("WCA\n(deg)", "Wind Collection Angle")}
                                    {props.waypointWeatherGeoJson.flightTimeInformationList.map((flightTimeInformation) => (
                                        displayFlightTimeValue(flightTimeInformation.wca_deg.toString())
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid >
                    </Grid>
                :
                <Alert severity="info" >フライトを選択するとwaypointごとの風情報が表示されます</Alert>
    )
}