import { FunctionPrivilegeInfo, DataPrivilegeInfo, GroupFunctionPrivilegeInfo, GroupDataPrivilegeInfo, Port, DroneInfo, Purpose, Specific, Report, FlightInfo, AssosiateFlightAndOrder, OrderInfo, parseOrderInfo, EnvSensorInfo, OperationHistory, FlightLog, GroupInfo, OperationPartnerInfo, BusinessPartnerInfo, UserPartnerInfo, GeoInformation, InitMode, MapURLInformation, CustomerURLInformation, UserInfo, WeatherGeoJson, ShipInformation, WaypointWeatherGeoJson } from 'adoms-common-lib';
import { Auth } from 'aws-amplify';
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import axios, { AxiosRequestConfig } from 'axios';
/**
 * マスター権限リスト（権限一覧画面用）
 */
type PrivilegeInfoList = {
  // マスター機能用権限リスト
  functionPrivilegeInfoList: FunctionPrivilegeInfo[]
  // マスターデータ用権限リスト
  dataPrivilegeInfoList: DataPrivilegeInfo[]
}

/**
 * マスターグループに紐づく権限リスト（権限一覧画面用）
 */
type GroupPrivilegeList = {
  // key: グループ名、value: 機能用権限リストのオブジェクト配列
  groupFunctionPrivilegeInfoList: GroupFunctionPrivilegeInfo[]
  // key: グループ名、value: データ用権限リストのオブジェクト配列
  groupDataPrivilegeInfoList: GroupDataPrivilegeInfo[]
}

export class APIConnector {
  /** インスタンス */
  private static _instance: APIConnector;

  /** インスタンスの取得 */
  public static get instance(): APIConnector {
    // _inctanceが存在しない場合に、new Hoge()を実行する。
    if (!this._instance) {
      console.log("create ConfigInstance");
      this._instance = new APIConnector();
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  private async makeHeader() {
    let idToken =
      await Auth.currentSession().then((data) => {
        return data.getIdToken().getJwtToken();
      }).catch((error) => {
        throw new Error('can\'t get token from session');
      });

    return {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': idToken
    }
  }

  /**
   * オペレーション用ポートリストを取得
   */
  public async getPortListForOperation() {
    let headers = await this.makeHeader().catch(error => {
      throw error;
    });
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: headers,
      responseType: 'json'
    });

    return await client.get(process.env.REACT_APP_API_PORTSFOROPERATION as string).then(
      function (response): Port[] {
        var portlist = new Array<Port>();
        console.log("getPortList response:" + JSON.stringify(response));
        for (const port of response.data) {
          portlist.push(port);
        }
        return portlist.sort((a: Port, b: Port) => { return (a.sortkey < b.sortkey) ? 1 : -1 });
      }
    ).catch((error) => { // 通信エラーが発生したら
      throw error;
    });
  }

  /**
   * ドローンリストを取得
   */
  public async getDroneList() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.post(process.env.REACT_APP_API_GETDRONELIST as string).then(
      function (response): DroneInfo[] {
        var droneList = new Array<DroneInfo>();
        console.log("getDroneList response:" + JSON.stringify(response.data));
        for (const drone of response.data) {
          droneList.push(drone);
        };
        return droneList.sort((a: DroneInfo, b: DroneInfo) => { return (a.sort > b.sort) ? 1 : -1 });
      }
    ).catch((error) => { // 通信エラーが発生したら
      console.log('通信失敗'); // ログに失敗と表示
      console.log(error.status); // エラーコードを表示
      throw error;
    });
  };

  /**
   * FDリストを取得
   */
  public async getFlightDirectorList() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.post(process.env.REACT_APP_API_GETFDLIST as string).then(
      function (response): UserInfo[] {
        var fdList = new Array<UserInfo>();
        console.log("getDroneList response:" + JSON.stringify(response.data));
        for (const fd of response.data) {
          fdList.push(fd);
        }
        return fdList;
      }
    ).catch((error) => { // 通信エラーが発生したら
      console.log('通信失敗:' + error); // ログに失敗と表示
      throw error;
    });
  }

  /**
   * 新しいフライトを作成する 
   */
  public async putFlight(flightDate: string,
    departure: string,
    arrival: string,
    drone: string,
    std: string,
    sta: string,
    fd: string,
    payload: number,
    purpose: Purpose,
    specificList?: Specific[],
    orderId?: string,
    partnerId?: string
  ): Promise<string> {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    const param = {
      flightDate: flightDate,
      partnerId: partnerId,
      std: std,
      sta: sta,
      arrival: arrival,
      departure: departure,
      fd: fd,
      drone: drone,
      payload: payload,
      orderId: orderId,
      purpose: purpose,
      specificList: specificList
    }

    return await client.post(process.env.REACT_APP_API_PUTFLIGHT as string, param).then(
      function (res): string {
        return res.data.id as string;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * フライトIDに紐づく注文ID（OrderId）を返す
   * @param flightInfo フライト情報
   * @returns フライトIDに紐づく注文IDのリスト
   */
  public async getOrderIdTiedToFlightId(flightInfo: FlightInfo): Promise<AssosiateFlightAndOrder[]> {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GETORDERIDTIEDTOFLIGHTID + "?flightId=" + flightInfo.id
    ).then((res) => {
      let assosiateFlightAndOrderList = new Array<AssosiateFlightAndOrder>();
      for (const assosiateFlightAndOrder of res.data) {
        assosiateFlightAndOrderList.push(assosiateFlightAndOrder);
      }
      return assosiateFlightAndOrderList;
    });
  }


  /**
   * 注文日を指定し配送依頼を取得する
   * @param orderDate
   */
  public async getOrderListByOrderDate(orderDate: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(process.env.REACT_APP_API_GETORDERLISTBYORDERDATE + "?orderDate=" + orderDate).then((res) => {
      console.log(res);
      let orders = res.data as OrderInfo[];
      return orders.sort((a, b) => { return (a.preferredDeliveryTime > b.preferredDeliveryTime) ? 1 : -1 });
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * フライトに紐づけされていないオーダーを取得する
   * @returns 
   */
  public async getOrderListForNoAssignedFlight() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(process.env.REACT_APP_API_GETORDERLISTFORNOASSIGNEDFLIGHT as string).then(
      function (res): OrderInfo[] {
        let orders = new Array<OrderInfo>();
        for (const order of res.data) {
          orders.push(order as OrderInfo);
        }
        return orders.sort((a, b) => { return (a.orderDate > b.orderDate) ? 1 : -1 });
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }


  /**
   * フライト日を指定しフライト一覧を取得する
   * @param flightDate
   */
  public async getFlightListByFlightDate(flightDate: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(process.env.REACT_APP_API_GETFLIGHTLISTBYFLIGHTDATE + "?flightDate=" + flightDate).then((res) => {
      let flightList = res.data as FlightInfo[];
      flightList = flightList.sort((a, b) => { return (a.std < b.std) ? 1 : -1 });
      return flightList.sort((a, b) => { return (a.flightDate > b.flightDate) ? 1 : -1 });
    });
  }

  /**
   * 配送情報を取得するハンドラ（オペレーション用のフロント向け）
   * @param orderID
   */
  public async getOrderForOperation(confirmationNumber: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.post(process.env.REACT_APP_API_GETORDERFOROPERATION as string, { orderID: confirmationNumber }).then(
      function (res): OrderInfo {
        return res.data as OrderInfo;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  public async getReciept(orderId: string, filePath: string): Promise<string> {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GETORDERRECEIPT + "?orderId=" + orderId +
      "&filePath=" + filePath
    ).then(response => {
      return response.data.receiptImageBase64 as string;
    });
  }

  /**
   * 配送のステータス、重量を変更する
   * @param orderInfo 
   */
  public async putOrderChange(orderInfo: OrderInfo,
    receiptImageBase64?: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.post(process.env.REACT_APP_API_PUTORDERCHANGE as string, {
      orderInfo: orderInfo,
      receiptImageBase64: receiptImageBase64
    }).then(
      function (res): OrderInfo {
        console.log("OrderInfo:" + res);
        return parseOrderInfo(res.data);
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }


  /**
   * フライトを取得する
   * @param id フライトID 
   */
  public async getFlight(id: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.post(process.env.REACT_APP_API_GETFLIGHT as string, { id: id }).then(
      function (res): FlightInfo {
        return res.data as FlightInfo;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * オーダーIDに紐づくフライトIDを取得する
   * @param orderInfo 注文依頼
   * @returns orderIdとFlightIdの連携情報
   */
  public async getFlightIdTiedToOrderId(orderId: string) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GETFLIGHTIDTIEDTOORDERID + "?orderId=" + orderId
    ).then(
      function (res): AssosiateFlightAndOrder[] {
        let assosiateFlightAndOrderList = new Array<AssosiateFlightAndOrder>();
        for (const assosiateFlightAndOrder of res.data) {
          assosiateFlightAndOrderList.push(assosiateFlightAndOrder);
        }
        return assosiateFlightAndOrderList;
      }
    );
  }


  /**
   * フライトのステータスと紐づくオーダーIDを変更する
   * @param flight 
   */
  public async putFlightStatusChange(flight: FlightInfo, orders: string[]) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    let params = {
      id: flight.id,
      status: flight.status,
      orders: orders,
    }

    return await client.post(process.env.REACT_APP_API_PUTFLIGHTSTATUSCHANGE as string, params).then(
      function (res): FlightInfo {
        console.log("FlightInfo:" + res);
        return res.data as FlightInfo;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  public async putFlightScheduleChange(flightScedule: {
    id: string,
    std: string,
    sta: string,
    etd: string,
    eta: string,
    remarks: string,
    report?: Report
  }): Promise<void> {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,  // バックエンドB のURL:port を指定する
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.post(process.env.REACT_APP_API_PUTFLIGHTSCHEDULECHANGE as string, flightScedule).then(() => {
      return Promise.resolve();
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * センサーデータを取得する
   * @param id デバイスID 
   */
  public async getEnvSensorData(id: string) {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(process.env.REACT_APP_API_GETENVSENSORVALUE + "?deviceID=" + id).then(
      function (res): EnvSensorInfo {
        return res.data as EnvSensorInfo;
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
  * ポート新規作成
  * @param newPort 新規作成するポート情報
  */
  public async createPort(newPort: Port) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    console.log("新規作成ポート情報" + JSON.stringify(newPort, null, 2));

    return await client.post(process.env.REACT_APP_API_PORT as string, newPort).then(
      function (res) {
        console.log("res:" + JSON.stringify(res));
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
  * ポート更新
  * @param originalPort 更新する元のポート情報
  * @param updatePort 更新後のポート情報
  */
  public async updatePort(originalPort: Port, updatePort: Port) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    console.log("更新前ポート情報" + JSON.stringify(originalPort, null, 2));
    console.log("更新後ポート情報" + JSON.stringify(updatePort, null, 2));

    let param = {
      originalPort: originalPort,
      updatePort: updatePort
    }

    return await client.put(process.env.REACT_APP_API_PORT as string, param).then(
      function (res) {
        console.log("res:" + JSON.stringify(res));
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * ポート削除
   * @param partnerId パートナーID
   * @param portId ポートID
   */
  public async deletePort(partnerId: string, portId: string) {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.delete(process.env.REACT_APP_API_PORT + "?partnerId=" + partnerId + "&portId=" + portId).then(
      function (res) {
        console.log("res:" + JSON.stringify(res));
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
  * フライト情報操作履歴を取得する
  * @param id フライトID 
  */
  public async getFlightHistory(id: string) {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(process.env.REACT_APP_API_FLIGHTHISTORY + "?flightId=" + id).then(
      function (res): OperationHistory[] {
        let operationHistoryList = new Array<OperationHistory>();
        for (const operationHistory of res.data) {
          operationHistoryList.push(operationHistory);
        }
        return operationHistoryList.sort((a, b) => { return (a.updateDateTime > b.updateDateTime) ? 1 : -1 });
      }
    ).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
  * 飛行実績を取得する
  * @param flightDateFrom
  * @param flightDateTo
  * @param droneId
  * @param fdSub
  */
  public async getFlightLog(flightDateFrom: string, flightDateTo: string, droneId: string, fdSub?: string): Promise<FlightLog[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    let queryStringParameters = "?flightDateFrom=" + flightDateFrom
      + "&flightDateTo=" + flightDateTo
      + "&droneId=" + droneId;

    if (typeof fdSub !== "undefined") {
      queryStringParameters += "&fdSub=" + fdSub
    };

    return await client.get(
      process.env.REACT_APP_API_FLIGHTLOG
      + queryStringParameters
    ).then(
      function (res): FlightLog[] {

        let flightLogList = new Array<FlightLog>();
        for (const flightLog of res.data) {
          flightLogList.push(flightLog);
        }
        return flightLogList;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  public async postPushNotificationInformation(token: string): Promise<void> {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    const requestSettings: AxiosRequestConfig = {
      withCredentials: true
    }

    await client.post(process.env.REACT_APP_API_PUSHNOTIFICATION as string, { token: token }, requestSettings).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  public async deletePushNotificationInformation(): Promise<void> {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    const requestSettings: AxiosRequestConfig = {
      withCredentials: true
    }

    await client.delete(process.env.REACT_APP_API_PUSHNOTIFICATION as string, requestSettings).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
  * ユーザーリストを取得する
  */
  public async getUserList(): Promise<UserInfo[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_USERLIST as string
    ).then(
      function (res): UserInfo[] {

        let userInfoList = new Array<UserInfo>();
        for (const userInfo of res.data) {
          userInfoList.push(userInfo);
        }
        return userInfoList;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * パートナーIDリストを登録する
  */
  public async putUserPartnerIdList(userInfo: UserInfo): Promise<void> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.put(
      process.env.REACT_APP_API_USERPARTNERIDLIST as string, userInfo
    ).then(() => {
      return Promise.resolve();

    }).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * グループリストと権限を登録する
  */
  public async putUserGroupList(userInfo: UserInfo): Promise<void> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.put(
      process.env.REACT_APP_API_USERPRIVILEGE as string, userInfo
    ).then(() => {
      return Promise.resolve();

    }).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * マスターグループリストを取得する
  */
  public async getMasterGroupList(): Promise<GroupInfo[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_MASTERGROUPLIST as string
    ).then(
      function (res): GroupInfo[] {
        let groupList = new Array<GroupInfo>();
        for (const group of res.data) {
          groupList.push(group);
        }
        return groupList;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * マスター権限リストを取得する
  */
  public async getMasterPrivilegeList(): Promise<PrivilegeInfoList> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_MASTERPRIVILEGELIST as string
    ).then(
      function (res): PrivilegeInfoList {

        let privilegeInfoList = {
          functionPrivilegeInfoList: res.data.functionPrivilegeInfoList,
          dataPrivilegeInfoList: res.data.dataPrivilegeInfoList
        }
        return privilegeInfoList;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * マスターグループに紐づく権限をそれぞれ取得する
  */
  public async getPrivilegeListPerGroup(): Promise<GroupPrivilegeList> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_PRIVILEGELISTPERGROUP as string
    ).then(
      function (res): GroupPrivilegeList {
        let groupPrivilegeList: GroupPrivilegeList = {
          groupFunctionPrivilegeInfoList: res.data.groupFunctionPrivilegeInfoList,
          groupDataPrivilegeInfoList: res.data.groupDataPrivilegeInfoList
        }
        return groupPrivilegeList;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * 全てのオペレーションパートナー情報を取得する
  */
  public async getOperationPartnerInfoList(): Promise<OperationPartnerInfo[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_OPERATIONPARTNERINFOLIST as string
    ).then(
      function (res): OperationPartnerInfo[] {
        let operationPartnerInfoList = new Array<OperationPartnerInfo>();
        for (const operationPartnerInfo of res.data) {
          operationPartnerInfoList.push(operationPartnerInfo);
        }
        return operationPartnerInfoList;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * 全てのビジネスパートナー情報を取得する
  */
  public async getBusinessPartnerInfoList(): Promise<BusinessPartnerInfo[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_BUSINESSPARTNERINFOLIST as string
    ).then(
      function (res): BusinessPartnerInfo[] {
        let businessPartnerInfoList = new Array<BusinessPartnerInfo>();
        for (const businessPartnerInfo of res.data) {
          businessPartnerInfoList.push(businessPartnerInfo);
        }
        return businessPartnerInfoList;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * ログインしているユーザーの情報を取得する
  */
  public async getUserInfo(): Promise<UserInfo> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_USERINFO as string
    ).then(
      function (res): UserInfo {
        return res.data as UserInfo;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * ユーザー情報を更新する
  */
  public async updateUserInfo(sub: string, competenceCertificateNumber?: string): Promise<void> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.put(
      process.env.REACT_APP_API_USERINFO as string,
      {
        sub: sub,
        competenceCertificateNumber: competenceCertificateNumber
      }
    ).then(() => {
      return Promise.resolve();
    }).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * ユーザー情報を登録する
  */
  public async signUp(
    name: string,
    email: string,
    userPartnerInfo: UserPartnerInfo,
    groupInfoList: GroupInfo[]
  ): Promise<void> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    const param = {
      name: name,
      email: email,
      userPartnerInfo: userPartnerInfo,
      groupInfoList: groupInfoList
    }

    return await client.post(
      process.env.REACT_APP_API_SIGNUP as string, param
    ).then(() => {
      return Promise.resolve();
    }).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * ユーザー情報を削除する
  */
  public async deleteUserInfoList(
    subList: string[]
  ): Promise<void> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    let multiValueQueryStringParameters = "";

    for (let sub of subList) {
      if (!multiValueQueryStringParameters) {
        multiValueQueryStringParameters += sub
      } else {
        multiValueQueryStringParameters += ("&subList=" + sub)
      };
    };

    return await client.delete(
      process.env.REACT_APP_API_USERINFO as string + "?subList=" + multiValueQueryStringParameters
    ).then(() => {
      return Promise.resolve();
    }).catch((error) => {
      console.log("deleteUserInfo error:" + JSON.stringify(error));
      throw error;
    });
  }

  /**
  * ドローンの位置情報を取得する
  * （最終1分間のドローンの位置情報が取得できなかった場合、8時間以内のドローンの最後の位置情報を取得する）
  */
  public async getGeoInformation(): Promise<Map<string,
    {
      last1MinuteGeoInformations?: GeoInformation[],
      last8HoursGeoInformation?: GeoInformation
    }>> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GEOINFORMATION_DRONE as string
    ).then(
      function (res): Map<string,
        {
          last1MinuteGeoInformations?: GeoInformation[],
          last8HoursGeoInformation?: GeoInformation
        }> {
        return new Map(Object.entries(res.data))
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * マップURLを作成する
  */
  public async putMapURLInformation(
    businessPartnerId: string,
    expirationDateTime: string,
    remarks?: string
  ): Promise<string> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    const param = {
      businessPartnerId: businessPartnerId,
      expirationDateTime: expirationDateTime,
      remarks: remarks
    }

    return await client.post(
      process.env.REACT_APP_API_MAPURLINFORMATION as string, param
    ).then(
      function (res): string {
        return res.data as string;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * フライトIDを検索条件とし、ドローンの位置情報を取得する
  */
  public async getGeoInformationByFlightId(flightId: string): Promise<GeoInformation[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_GEOINFORMATION_FLIGHTID + "?flightId=" + flightId
    ).then(
      function (res): GeoInformation[] {
        return res.data as GeoInformation[];
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  public async init(mode: InitMode) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_INIT + "?mode=" + mode
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }

  /**
  * マップURL一覧を取得する
  */
  public async getMapURLInfoList(): Promise<MapURLInformation[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_MAPURLINFORMATIONLIST as string
    ).then(
      function (res): MapURLInformation[] {
        return res.data as MapURLInformation[];
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * 配送依頼用サイトURL一覧を取得する
  */
  public async getCustomerURLInformationList(): Promise<CustomerURLInformation[]> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    return await client.get(
      process.env.REACT_APP_API_CUSTOMER_URL_INFO_LIST as string
    ).then(
      function (res): CustomerURLInformation[] {
        return res.data as CustomerURLInformation[];
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * マップURLを作成する
  */
  public async putCustomerURLInformation(
    businessPartnerId: string,
    expirationDateTime: string,
    remarks?: string
  ): Promise<string> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    const param = {
      businessPartnerId: businessPartnerId,
      expirationDateTime: expirationDateTime,
      remarks: remarks
    }

    return await client.post(
      process.env.REACT_APP_API_CUSTOMER_URL_INFO as string, param
    ).then(
      function (res): string {
        return res.data as string;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * 気象GeoJSONを取得する
  */
  public async getWeatherGeoJson(displayDateJST_YYYYMMDD: string,
    displayTimeJST_HHMM: string,
    latStart: string,
    latEnd: string,
    lonStart: string,
    lonEnd: string,
    grid: string,
    altitude?: string,
    altSurface?: string): Promise<WeatherGeoJson> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    let queryStringParameters = process.env.REACT_APP_API_WEATHERINFO as string
      + "?displayDateJST_YYYYMMDD=" + displayDateJST_YYYYMMDD
      + "&displayTimeJST_HHMM=" + displayTimeJST_HHMM
      + "&latStart=" + latStart
      + "&latEnd=" + latEnd
      + "&lonStart=" + lonStart
      + "&lonEnd=" + lonEnd
      + "&grid=" + grid;

    if ((altitude && altSurface)
      || (!altitude && !altSurface)) {
      throw new Error("altitudeかaltSurfaceを指定してください");
    } else if (altitude) {
      queryStringParameters
        += "&altitude=" + altitude
    } else if (altSurface) {
      queryStringParameters
        += "&altSurface=" + altSurface
    };

    return await client.get(queryStringParameters).then(
      function (res): WeatherGeoJson {
        return res.data as WeatherGeoJson;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * ルートごとの気象GeoJSONを取得する
  */
  public async getMultipleWeatherGeoJson(displayDateJST_YYYYMMDD: string,
    displayTimeJST_HHMM: string,
    grid: string,
    altitude: string,
    businessPartnerId: string,
    departurePortId: string,
    arrivalPortId: string,
  ): Promise<WeatherGeoJson> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    let queryStringParameters = process.env.REACT_APP_API_WEATHERINFO_MULTIPLE as string
      + "?displayDateJST_YYYYMMDD=" + displayDateJST_YYYYMMDD
      + "&displayTimeJST_HHMM=" + displayTimeJST_HHMM
      + "&grid=" + grid
      + "&altitude=" + altitude
      + "&businessPartnerId=" + businessPartnerId
      + "&departurePortId=" + departurePortId
      + "&arrivalPortId=" + arrivalPortId

    return await client.get(queryStringParameters).then(
      function (res): WeatherGeoJson {
        return res.data as WeatherGeoJson;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
  * ルートをGeoJSON型で取得する
  */
  public async getRoute(businessPartnerId: string,
    departurePortId: string,
    arrivalPortId: string): Promise<FeatureCollection<Geometry, GeoJsonProperties>> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    let queryStringParameters = process.env.REACT_APP_API_GEOINFORMATION_ROUTE as string
      + "?businessPartnerId=" + businessPartnerId
      + "&departurePortId=" + departurePortId
      + "&arrivalPortId=" + arrivalPortId

    return await client.get(queryStringParameters).then(
      function (res): FeatureCollection<Geometry, GeoJsonProperties> {
        return res.data as FeatureCollection<Geometry, GeoJsonProperties>;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };

  /**
   * 船舶情報のリストを取得
   */
  public async getAisList() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    type BothShipInformation = {
      aisDataList: ShipInformation[], 
      lostAisDataList: ShipInformation[]
    }
    return await client.get(
      process.env.REACT_APP_API_AIS as string
    ).then(
      function (res): BothShipInformation {
        let aisDataList = new Array();
        let lostAisDataList = new Array();
        for (let aisInfo of res.data.aisInfoList) {
          aisDataList.push(aisInfo);
        };
        for (let lostAisInfo of res.data.lostAisDataList) {
          lostAisDataList.push(lostAisInfo);
        };
        let BothShipInformation = {
          aisDataList: aisDataList,
          lostAisDataList: lostAisDataList
        }
        return BothShipInformation;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  }
  
  /**
  * Waypointと高度ごとの気象GeoJSONを取得する
  */
  public async getWeatherGeoJsonByWaypoint(displayDateJST_YYYYMMDD: string,
    displayTimeJST_HHMM: string,
    grid: string,
    altitudeLowerLimit: number,
    altitudeUpperLimit: number,
    businessPartnerId: string,
    departurePortId: string,
    arrivalPortId: string,
  ): Promise<WaypointWeatherGeoJson> {

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: await this.makeHeader(),
      responseType: 'json'
    });

    let queryStringParameters = process.env.REACT_APP_API_WEATHERINFO_WAYPOINT as string
      + "?displayDateJST_YYYYMMDD=" + displayDateJST_YYYYMMDD
      + "&displayTimeJST_HHMM=" + displayTimeJST_HHMM
      + "&grid=" + grid
      + "&altitudeLowerLimit=" + altitudeLowerLimit
      + "&altitudeUpperLimit=" + altitudeUpperLimit
      + "&businessPartnerId=" + businessPartnerId
      + "&departurePortId=" + departurePortId
      + "&arrivalPortId=" + arrivalPortId

    return await client.get(queryStringParameters).then(
      function (res): WaypointWeatherGeoJson {
        return res.data as WaypointWeatherGeoJson;
      }
    ).catch((error) => {
      console.log(JSON.stringify(error));
      throw error;
    });
  };
}
